import { actionsFor } from '@infosight/elmer/dist/utils/redux';
import Request from '../services/Request';
import {
  FETCH_BULLETINS,
  FETCH_ACTIVE_BULLETINS,
  RESET,
  DISMISS_BULLETIN,
  DISMISS_ALL_BULLETINS,
  FETCH_WHATS_NEW,
  FETCH_ACTIVE_WHATS_NEW,
  DISMISS_ALL_WHATS_NEW,
} from './constants';

const fetchBulletins =
  ({ all }) =>
  async (dispatch) => {
    const action = all ? FETCH_BULLETINS : FETCH_ACTIVE_BULLETINS;
    const { dispatchStart, dispatchSuccess, dispatchError } = actionsFor(
      action,
      dispatch
    );
    dispatchStart();

    try {
      const response = await new Request()
        .slash('user', 'banners')
        .query({ all })
        .get();

      // TODO this is a stopgap to catch improper errors being passed as successes. this should probably be removed and the backend fixed
      if (
        Array.isArray(response.data) ||
        response.data === undefined ||
        response.data === null
      ) {
        dispatchSuccess(response.data);
        return response.data;
      }
      dispatchError(response.data);
      return null;
    } catch (ex) {
      dispatchError(ex);
      return null;
    }
  };

export const fetchActiveBulletins = () => fetchBulletins({ all: false });
export const fetchAllBulletins = () => fetchBulletins({ all: true });
export const reset = () => ({ type: RESET });
export const dismissAllBulletins = () => ({ type: DISMISS_ALL_BULLETINS });
export const dismissBulletin = (id) => ({
  type: DISMISS_BULLETIN,
  payload: id,
});

const fetchWhatsNew =
  ({ all }) =>
  async (dispatch) => {
    const action = all ? FETCH_WHATS_NEW : FETCH_ACTIVE_WHATS_NEW;
    const { dispatchStart, dispatchSuccess, dispatchError } = actionsFor(
      action,
      dispatch
    );
    dispatchStart();

    try {
      const response = await new Request()
        .slash('user', 'banners', 'whatsnew')
        .query({ all })
        .get();

      // TODO this is a stopgap to catch improper errors being passed as successes. this should probably be removed and the backend fixed
      if (
        Array.isArray(response.data) ||
        response.data === undefined ||
        response.data === null
      ) {
        dispatchSuccess(response.data);
        return response.data;
      }
      dispatchError(response.data);
      return null;
    } catch (ex) {
      dispatchError(ex);
      return null;
    }
  };

export const dismissAllWhatsNew = () => ({ type: DISMISS_ALL_WHATS_NEW });
export const fetchActiveWhatsNew = () => fetchWhatsNew({ all: false });
export const fetchAllWhatsNew = () => fetchWhatsNew({ all: true });
