import React from 'react';
import Shepherd from 'shepherd.js';
import ReactDOM from 'react-dom';
import { Paragraph } from 'grommet';
import * as defaultInteractionTracker from './interactionTracker';
import Callout from './components/Callout';
import { FOOTER_TYPE } from './constants';

const getStepElement = (step) =>
  document.querySelector(`[data-shell-shepherd-step-id="${step.id}"]`);
const replaceArrow = (step) => {
  const arrow = getStepElement(step).querySelector(
    `[data-shell-shepherd-step-id = "${step.id}"] .shepherd-arrow`
  );
  if (!arrow) {
    return;
  }

  // Hide the arrow when the element does not yet exist
  // See https://github.com/shipshapecode/shepherd/issues/813
  if (
    step.options &&
    step.options.attachTo &&
    typeof step.options.attachTo.element === 'string'
  ) {
    if (document.querySelectorAll(step.options.attachTo.element).length) {
      arrow.classList.remove('hide');
    } else {
      arrow.classList.add('hide');
    }
  }

  const shadow = document.createElement('div');
  shadow.classList.add('shepherd-arrow-shadow');

  const shape = document.createElement('div');
  shape.classList.add('shepherd-arrow-shape');

  shadow.appendChild(shape);
  arrow.appendChild(shadow);
};

/**
 * Takes the requested footer type as a hint, but applies some sensible settings to resolve errors, such as copy-paste-edit mistakes.
 * Also sets sensible defaults when there is no hint
 */
export const getFooterType = (footerHint, currentStepIndex, stepCount) => {
  let footerType;
  const isLastStep = currentStepIndex === stepCount - 1;
  const isFirstStep = currentStepIndex === 0;

  if (Object.values(FOOTER_TYPE).includes(footerHint)) {
    footerType = footerHint;
  } else if (!isLastStep && !isFirstStep) {
    footerType = FOOTER_TYPE.BIDIRECTIONAL;
  } else if (isLastStep) {
    footerType = FOOTER_TYPE.CONCLUSION;
  }

  if (isFirstStep) {
    footerType = FOOTER_TYPE.INTRO;
  } else if (
    isLastStep &&
    footerType !== FOOTER_TYPE.CONCLUSION_ONLY &&
    footerType !== FOOTER_TYPE.CONCLUSION &&
    footerType !== FOOTER_TYPE.FORWARD_ONLY
  ) {
    footerType = FOOTER_TYPE.CONCLUSION;
  } else if (
    !isLastStep &&
    !isFirstStep &&
    footerType !== FOOTER_TYPE.BIDIRECTIONAL &&
    footerType !== FOOTER_TYPE.FORWARD_ONLY
  ) {
    footerType = FOOTER_TYPE.BIDIRECTIONAL;
  }

  if (footerType === FOOTER_TYPE.BIDIRECTIONAL && isLastStep) {
    footerType = FOOTER_TYPE.CONCLUSION;
  } else if (footerType === FOOTER_TYPE.FORWARD_ONLY && isLastStep) {
    footerType = FOOTER_TYPE.CONCLUSION_ONLY;
  }

  return footerType;
};

/**
 * Uses a selector function to keep a reference to an element to avoid repeated DOM lookups
 * @param elementGetter
 * @return {function(): HTMLElement}
 */
const memoizeElement = (elementGetter) => {
  let element;
  return () => {
    if (!element) {
      element = elementGetter();
    }

    return element;
  };
};

/**
 * TODO completely document all the overrides and enhancements we do to the shepherd options
 * @param {string} appId - Microapp ID
 * @param {string} name - Unique human-readable name. It will be slugified and used to create the id and event names in NR
 * @param {string} id - Globally unique, url-safe ID based on a concatenation of appId and name
 * @param {string|Number} version - Consumer defined version that indicates that changes were made, but the tour is materially the same
 * @param hideBeacon - If the beacon should be hidden when the tour becomes active
 * @param {Array<Shepherd.Step>} steps - Array of step creation options for a Shepherd.Step
 * @param {Object<string, function>} customTourEvents - Hash of Shepherd.Tour events and callbacks
 * @param {Shepherd.Tour} shepherdOptions - Any shepherd option passed to the Tour constructor
 * @param stopTour
 * @param interactionTracker
 * @returns {Tour}
 */
export default function createShepherdTour(
  {
    appId,
    name,
    id,
    version,
    hideBeacon,
    shepherdOptions: { steps, events: customTourEvents, ...shepherdOptions },
  },
  stopTour,
  interactionTracker = defaultInteractionTracker
) {
  const tour = new Shepherd.Tour({
    ...shepherdOptions,
    useModalOverlay: true,
    tourName: id,
    classPrefix: 'shell',
  });

  // Keep a reference to custom step options without polluting the Step object.
  const stepOptionsMap = new WeakMap();

  tour.on('complete', () => {
    stopTour({ appId, name });
    interactionTracker.trackCompleted({ appId, name, id, version });
  });

  tour.on('cancel', () => {
    const stepOptions = stepOptionsMap.get(tour.getCurrentStep());

    stopTour({ appId, name });

    if (stepOptions && !stepOptions.isFirst) {
      interactionTracker.trackEarlyExit({
        appId,
        name,
        id,
        version,
        stepTitle: stepOptions.title,
      });
    }
  });

  if (customTourEvents) {
    Object.entries(customTourEvents).forEach(([event, callback]) => {
      tour.on(event, callback);
    });
  }

  steps
    .filter((step) => !!step)
    .filter(({ showOn }) => (typeof showOn === 'function' ? !!showOn() : true))
    .forEach(
      (
        {
          title,
          text,
          buttons,
          beforeShowPromise,
          popperOptions,
          noFooter,
          largeModal,
          ...stepOptions
        },
        index,
        arr
      ) => {
        let enhancedBeforeShowPromise;
        let step;
        if (beforeShowPromise && typeof beforeShowPromise === 'function') {
          // Enhance the Shepherd API by passing in the step instance so the consumer can update stuff as needed.
          enhancedBeforeShowPromise = async () => beforeShowPromise({ step });
        }

        const getArrowOverlay = memoizeElement(() =>
          getStepElement(step).querySelector('.shepherd-arrow-overlay')
        );

        step = tour.addStep({
          ...stepOptions,
          buttons: null,
          title: null,
          beforeShowPromise: enhancedBeforeShowPromise,
          cancelIcon: { enabled: false },
          popperOptions: {
            modifiers: [
              // Offset from the element by the width of the arrow. See https://popper.js.org/docs/v2/modifiers/offset/
              { name: 'offset', options: { offset: [0, 12] } },

              // This is a custom modifier that copies the style from the arrow to the overlay since the default position of is in the center
              // See https://popper.js.org/docs/v2/modifiers/#custom-modifiers
              {
                name: 'moveOverlay',
                enabled: true,
                phase: 'afterWrite',
                fn({ state }) {
                  if (
                    state.elements &&
                    state.elements.arrow &&
                    getArrowOverlay()
                  ) {
                    getArrowOverlay().style.cssText =
                      state.elements.arrow.style.cssText;
                  }
                },
              },
            ],
          },
          text() {
            const el = document.createElement('div');
            ReactDOM.render(
              <Callout
                cancel={() => {
                  tour.cancel();
                  interactionTracker.trackEarlyExit({
                    appId,
                    name,
                    id,
                    version,
                  });
                }}
                steps={arr.length}
                currentStepIndex={index}
                complete={tour.complete}
                snooze={() => {
                  tour.cancel();
                  interactionTracker.trackSnooze({ appId, name, id, version });
                }}
                goToNext={tour.next}
                goToPrevious={tour.back}
                title={title}
                largeModal={largeModal}
                footer={!noFooter && getFooterType(buttons, index, arr.length)}
                body={
                  typeof text === 'function' ? (
                    text(tour)
                  ) : (
                    <Paragraph fill>{text}</Paragraph>
                  )
                }
                onAcceptTour={() => {
                  tour.next();
                  if (hideBeacon) {
                    interactionTracker.trackHidden({
                      appId,
                      name,
                      id,
                      version,
                    });
                  } else {
                    interactionTracker.trackStart({ appId, name, id, version });
                  }
                }}
              />,
              el
            );
            return el;
          },
        });

        stepOptionsMap.set(step, { title, isFirst: index === 0 });

        step.on('show', () => replaceArrow(step));
      }
    );

  return tour;
}
