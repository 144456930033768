import { noop, intersection } from 'underscore';
import { INTERNAL_ROLES } from '../../constants';

/**
 * Some segments are just noise, so they should be removed
 */
const pathReplacements = [/\/tenant\/:tenantId/];

const trackPageView = ({ path }) => {
  if (!path) {
    return;
  }

  const cleanPath = pathReplacements.reduce(
    (memo, replacement) => memo.replace(replacement, ''),
    path
  );
  // eslint-disable-next-line no-undef
  ga('send', 'pageview', cleanPath);
};

const trackEvent = noop;

const setDimensions = (roles, userId) => {
  // TODO See https://nimblejira.nimblestorage.com/browse/IS-35228
  if (roles) {
    // We use the Google Analytics 'Custom Dimensions' feature to track sessions by user role.
    // See https://support.google.com/analytics/answer/2709828

    // Report user role(s) in Custom Dimension 1
    // eslint-disable-next-line no-undef
    ga(
      'set',
      'dimension1',
      roles
        .filter(
          (role) =>
            // Omit 'any' and 'strongpass' roles for analytics
            role !== 'any' && role !== 'strongpass'
        )
        .join(',')
    );

    // Report internal vs external user in Custom Dimension 2
    // eslint-disable-next-line no-undef
    ga(
      'set',
      'dimension2',
      intersection(roles, INTERNAL_ROLES).length > 0 ? 'internal' : 'external'
    );

    // Report strongpass vs non-strongpass user in Custom Dimension 3
    // eslint-disable-next-line no-undef
    ga(
      'set',
      'dimension3',
      roles.includes('strongpass') ? 'strongpass' : 'non-strongpass'
    );
  }

  // Report unique user ID
  if (userId) {
    // eslint-disable-next-line no-undef
    ga('set', 'userId', userId);
  }
};

const setSystemCountDimensions = (inventory) => {
  if (!inventory) return;
  // Report whether this user has storeserv systems in Custom Dimension 4
  // eslint-disable-next-line no-undef
  ga(
    'set',
    'dimension4',
    !!inventory.storeserv && inventory.storeserv?.presence
  );
  // Report whether this user has storeonce systems in Custom Dimension 5
  // eslint-disable-next-line no-undef
  ga(
    'set',
    'dimension5',
    !!inventory.storeonce && inventory.storeonce?.presence
  );
  // We have deleted Custom Dimension 6 which was for RMC systems
  // Report whether this user has nimble systems in Custom Dimension 7
  // eslint-disable-next-line no-undef
  ga('set', 'dimension7', !!inventory.nimble && inventory.nimble?.presence);
  // eslint-disable-next-line no-undef
  // Report whether this user has simplivity systems in Custom Dimension 9
  // eslint-disable-next-line no-undef
  ga(
    'set',
    'dimension9',
    !!inventory.simplivity && inventory.simplivity?.presence
  );
};

const init = () => true;

export default {
  trackEvent,
  trackPageView,
  init,
  setDimensions,
  setSystemCountDimensions,
  name: 'Google Analytics',
};
