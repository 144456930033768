/* eslint-disable import/prefer-default-export */
import { flatten } from 'underscore';
import { startTour } from '@infosight/shell-api/lib/Tour';
import {
  microappTitles,
  microappData,
  getNavPrecedence,
  getParentMicroapp,
} from '../router/knownRoutes';
import { shouldOfferTour } from '../tour/services';

export const getEnvLabel = (host) => {
  if (host === 'infosight.hpe.com') {
    return null;
  }
  if (host && host.endsWith('.infosight.hpe.com')) {
    return host.replace('.infosight.hpe.com', '');
  }
  return 'local';
};

const navMenuBeaconOffset = -6;

/**
 * The most common trigger for feature tours is a beacon associated with a nav item.
 * Microapps can very simply associate the tour with the nav item via an additional field like this:
 *  {
 *      ...
 *      tour: { appId: "iam", name: "org management" }
 *  }
 *
 * This visitor saves them from having to implement repeatable logic that should be consistent across microapps by converting the `tour` field to a
 * `beacon` field that is supported by the Nav components in elmer.
 *
 * This does not mutate the input option, it returns a new object
 *
 * @param option - Meganav or Menu option, or AdaptiveNode props that come from the standard addNav event
 * @param tourUserProperties - current status of each tour by ID
 * @param tours - Map of known tours
 */
export const createTourBeacons = (
  options,
  tourUserProperties,
  tours,
  offset = 8
) => {
  if (!options) {
    return options;
  }

  return options.map((option) => {
    if (!option) {
      return option;
    }
    const { tour, ...newOption } = option;
    if (tour) {
      // Always add the beacon field if there is a tour field to simplify debugging
      newOption.beacon = {
        enabled: shouldOfferTour({ tours, tourUserProperties, tourId: tour }),
        onClick() {
          startTour(tour);
        },
        anchor: 'left',
        offset,
      };
    }

    // If there are child options, visit each option node and expand the tour field into a beacon field
    if (option.options && Array.isArray(option.options)) {
      newOption.options = createTourBeacons(
        newOption.options,
        tourUserProperties,
        tours,
        navMenuBeaconOffset
      );
    }

    return newOption;
  });
};

const getAlwaysShow = (microapp) =>
  (microappData[microapp] && microappData[microapp].alwaysShow) || false;

const alwaysShowExperimental = (microapp) =>
  (microappData[microapp] && microappData[microapp].alwaysShowExperimental) ||
  false;

const isExperimentalApp = (microapp) =>
  (microappData[microapp] && microappData[microapp].experimental) || false;

const sortObjectByKeys = (unorderedObj, compareFunc) => {
  const orderedObj = {};

  Object.keys(unorderedObj)
    .sort(compareFunc)
    .forEach((key) => {
      orderedObj[key] = unorderedObj[key];
    });

  return orderedObj;
};

const flattenOptions = (data) =>
  flatten(
    data.map((e) => (e.options && e.options.options) || e.options || e),
    1
  )
    .filter((o) => o)
    .map((e) =>
      e.optionsNext ? { ...e, url: null, options: e.optionsNext } : e
    );

const upsertElements = ({ microapps, microapp, elements, reverseOrder }) => {
  if (microapps[microapp]) {
    if (reverseOrder) {
      // eslint-disable-next-line no-param-reassign
      microapps[microapp].elements = [
        ...elements,
        ...microapps[microapp].elements,
      ];
    } else {
      // eslint-disable-next-line no-param-reassign
      microapps[microapp].elements = [
        ...microapps[microapp].elements,
        ...elements,
      ];
    }
  }
};

const upsertNestedElement = ({
  microapps,
  microapp,
  id,
  options,
  title,
  reverseOrder,
}) => {
  if (microapps[microapp]) {
    const existing = microapps[microapp].elements.find((el) => el.id === id);
    if (existing) {
      if (reverseOrder) {
        existing.options = [...options, ...existing.options];
      } else {
        existing.options = [...existing.options, ...options];
      }
    } else {
      // We want to add an accordian of options into the microapp
      microapps[microapp].elements.push({
        title,
        id,
        options,
      });
    }
  }
};

const formatInfrastructure = ({ childMicroapp, data }) => {
  const microapp = getParentMicroapp(childMicroapp);
  const elements = flattenOptions(data);
  const title =
    (data[0].options && data[0].options.title) ||
    microappTitles[microapp] ||
    microapp;

  return { microapp, elements, title };
};

const addDashboardQualifier = (d) =>
  d.title !== 'Labs' && !(d.title && d.title.endsWith('Dashboard'))
    ? { ...d, qualifier: 'dashboard' }
    : d;

const flattenDashOptions = (data) =>
  flattenOptions(data)
    .map((d) =>
      d.title && typeof d.title !== 'string'
        ? { ...d, title: d.label || d.id }
        : d
    )
    .map(addDashboardQualifier);

const formatDashboard = ({ childMicroapp, data }) => {
  const microapp = getParentMicroapp(childMicroapp);
  const elements = flattenDashOptions(data);
  const title = microappTitles[microapp] || data[0].title || microapp;

  return { microapp, elements, title };
};

const formatOtherNavOptions = ({
  data,
  childMicroapp,
  microapps,
  id,
  title,
}) => {
  // flatten options into the array
  const options = flattenOptions(data);
  const microapp = getParentMicroapp(childMicroapp);

  const formattedOptions = [];
  const formattedCoreOptions = [];
  if (microapps[microapp]) {
    formattedOptions.push({ microapp, id, options, title });
  } else if (microapp === 'oculus') {
    ['storeserv', 'storeonce'].forEach((oculusMicroapp) => {
      if (microapps[oculusMicroapp]) {
        formattedOptions.push({
          microapp: oculusMicroapp,
          id,
          options,
          title,
        });
      }
    });
  } else if (microappData[microapp] && microappData[microapp].isCore) {
    options.forEach((option) => {
      // this if statement drops experimental mode.
      if (option.url) {
        formattedCoreOptions.push(option);
      }
    });
  }

  return { formattedOptions, formattedCoreOptions };
};

export const restructureNavData = ({
  dashboards,
  infrastructure,
  settings: settingsParam,
  resources: resourcesParam,
  microappManifest,
  inventory,
  tourUserProperties,
  tours,
  experimentalMode,
}) => {
  const microapps = {};
  if (microappManifest && inventory) {
    microappManifest.forEach(({ id }) => {
      const appId = getParentMicroapp(id);
      if (
        (!isExperimentalApp(id) ||
          (experimentalMode && isExperimentalApp(id))) &&
        (getAlwaysShow(id) ||
          (experimentalMode && alwaysShowExperimental(id)) ||
          (inventory[appId] && inventory[appId].presence) ||
          (inventory[id] && inventory[id].presence))
      ) {
        if (microapps[appId]) {
          microapps[appId].childAppIds.push(id);
        } else {
          microapps[appId] = {
            title: microappTitles[appId] || appId,
            elements: [],
            childAppIds: [id],
          };
        }
      }
    });
  } else {
    return { microapps: {}, settings: [], resources: [] };
  }

  if (infrastructure) {
    Object.entries(infrastructure).forEach(([childMicroapp, data]) => {
      if (!data || !data[0]) {
        return;
      }
      const formattedMicroapp = formatInfrastructure({
        childMicroapp,
        data,
      });
      upsertElements({ microapps, ...formattedMicroapp });
    });
  }

  if (dashboards) {
    Object.entries(dashboards).forEach(([childMicroapp, data]) => {
      if (!dashboards[childMicroapp]) {
        return;
      }

      const formattedMicroapp = formatDashboard({ childMicroapp, data });
      upsertElements({
        microapps,
        ...formattedMicroapp,
        reverseOrder: true,
      });
    });
  }

  // For the microapps that have already been added, add each app to microapps it is replicated in.
  // These are different than a normal child because it should not add the parent if the parent has not been added already
  // This must come after all the main content
  // First so that the place it is being replicated in already exists and is fully populated.
  // Second so that the microapp itself has been formatted
  Object.keys(microapps).forEach((appId) => {
    const replicatedList =
      microappData[appId] && microappData[appId].replicatedIn;
    if (replicatedList) {
      replicatedList.forEach((containerId) => {
        if (microapps[containerId]) {
          // add it as a child of the apps
          microapps[containerId].childAppIds.push(appId);
          // insert the formatted microapp
          if (
            microappData[appId].replicatedIn &&
            microappData[appId].replicatedIn.includes(containerId)
          ) {
            const { elements, title } = microapps[appId];
            upsertNestedElement({
              microapps,
              microapp: containerId,
              id: appId,
              options: elements || [],
              title,
            });
          }
        }
      });
    }
  });

  const coreResources = [];
  let resources = [];
  if (resourcesParam) {
    Object.entries(resourcesParam).forEach(([childMicroapp, data]) => {
      if (!data || data.length === 0) {
        return;
      }

      // flatten options into the array
      const options = flattenOptions(data);
      const microapp = getParentMicroapp(childMicroapp);

      const { formattedOptions, formattedCoreOptions } = formatOtherNavOptions({
        childMicroapp,
        data,
        microapps,
        id: 'support',
        title: 'Resources',
      });

      formattedOptions.forEach((resource) => {
        upsertNestedElement({ microapps, ...resource });
      });

      // insert into top level
      if (!(microappData[microapp] && microappData[microapp].isCore)) {
        const existingResource = resources.find((el) => el.id === microapp);
        if (existingResource) {
          existingResource.options = [...existingResource.options, ...options];
        } else {
          resources.push({
            title:
              (microapps[microapp] && microapps[microapp].title) ||
              microappTitles[microapp] ||
              microapp,
            id: microapp,
            options,
          });
        }
      }

      coreResources.push(...formattedCoreOptions);
    });

    // Push all global resources into an InfoSight menu
    if (coreResources.length > 0) {
      resources.unshift({
        id: 'general',
        title: 'General',
        options: coreResources,
      });
    }
  }

  // Sort resources to precedence order within the Nav menu
  resources.sort((a, b) => {
    return getNavPrecedence(b.id) - getNavPrecedence(a.id);
  });

  let settings = [];
  if (settingsParam) {
    Object.entries(settingsParam).forEach(([childMicroapp, data]) => {
      if (!data || data.length === 0) {
        return;
      }

      const { formattedOptions, formattedCoreOptions } = formatOtherNavOptions({
        childMicroapp,
        data,
        microapps,
        id: 'settings',
        title: 'Settings',
      });

      formattedOptions.forEach((setting) =>
        upsertNestedElement({ microapps, ...setting })
      );
      settings.push(...formattedCoreOptions);
    });
  }

  // Add tour beacons to any nav component that has a tour field
  Object.entries(microapps).forEach(([appId, microapp]) => {
    microapps[appId] = microapp
      ? {
          ...microapp,
          elements: createTourBeacons(
            microapp.elements,
            tourUserProperties,
            tours,
            navMenuBeaconOffset
          ),
        }
      : microapp;
  });
  settings = createTourBeacons(
    settings,
    tourUserProperties,
    tours,
    navMenuBeaconOffset
  );
  resources = createTourBeacons(
    resources,
    tourUserProperties,
    tours,
    navMenuBeaconOffset
  );

  Object.keys(microappData).forEach((appId) => {
    if (microapps[appId] && microappData[appId].dontShow) {
      delete microapps[appId];
    }
  });

  return {
    microapps: sortObjectByKeys(microapps, (a, b) => {
      return getNavPrecedence(b) - getNavPrecedence(a);
    }),
    settings,
    resources,
  };
};
