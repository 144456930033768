import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'react-autobind';
import FlashNotification from '@infosight/elmer/dist/components/FlashNotification';
import ScrollContainer from '@infosight/elmer/dist/components/ScrollContainer';
import Spinner from '@infosight/elmer/dist/components/Spinner';
import { AutoAnalyticsProvider } from '@glcp/greenlake-analytics';
import Banners from '../banner';
import { loadPreferences } from '../preferences/actionCreators';
import HistoryAwareLinkAdapter from './HistoryAwareLinkAdapter';
import LegacyHashRouteAdapter from './LegacyHashRouteAdapter';
import WelcomeBanner from '../landing/components/WelcomeBanner';
import FullAccessBanner from '../fullAccess/FullAccessBanner';
import { bootstrapSelector } from './reducer';
import { alreadyFetchedPreferences } from '../preferences/reducer';
import Router from '../router/Router';
import PrimaryNavBar from '../primaryNav';
import { getUserInfo } from '../landing/selectors';

import {
  fetchIamSession,
  fetchCurrentUserUserTenants,
} from '../iam/actionCreators';
import {
  sessionSelector,
  currentUserUserTenantsSelector,
} from '../iam/reducer';
import { fetchBootstrap } from './actionCreators';
import { fetchActiveWhatsNew } from '../bulletins/actionCreators';
import { activeWhatsNewSelector } from '../bulletins/reducer';
import { fetchMaintenance } from '../maintenance/actionCreators';
import { UnsupportedBrowserBanner } from '../fullAccess/UnsupportedBrowserBanner';
import DemoRole from '../demo/DemoRole';
import InventoryReporter from '../inventory/InventoryReporter';
import NavFeatureTour from './NavFeatureTour';

const { Scrollable, Fixed } = ScrollContainer;

const amplitudeKey = 'efed22df112abd56ae230061b0c1d8ce';
const amplitudeKeyDev = '14a8894b1463c3c7800d7bc668b57d50';

const detectEnvironment = () => {
  switch (window.location.host) {
    case 'infosight.hpe.com':
      return ['Production', amplitudeKey];
    case 'beta.infosight.hpe.com':
      return ['Beta', amplitudeKeyDev];
    case 'dev.infosight.hpe.com':
      return ['Development', amplitudeKeyDev];
    default:
      return ['other', amplitudeKeyDev];
  }
};

class AppContainer extends Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidMount() {
    if (!this.props.loadingSession && !this.props.loadedSession) {
      this.props.fetchIamSession();
    }
    if (
      !this.props.loadingCurrentUserUserTenants &&
      !this.props.loadedCurrentUserUserTenants
    ) {
      this.props.fetchCurrentUserUserTenants();
    }
    if (!this.props.alreadyFetchedPreferences) {
      this.props.loadPreferences();
    }
    if (!this.props.loadingBootstrap && !this.props.loadedBootstrap) {
      this.props.fetchBootstrap();
    }
    if (!this.props.loadingActiveWhatsNew && !this.props.loadedActiveWhatsNew) {
      this.props.fetchActiveWhatsNew();
    }
    if (!this.props.loadingMaintenance && !this.props.loadedMaintenance) {
      this.props.fetchMaintenance();
    }
  }

  render() {
    let userProfileObject = {};
    let analyticsConfigDev = {};
    if (this.props.getUserInfoData && this.props.getUserInfoData.username) {
      const { username, microapps } = this.props.getUserInfoData;
      const [env, API_KEY] = detectEnvironment();
      analyticsConfigDev = {
        debugMessages: true,
        allowConsentOverrides: true,
        autoAnalytics: {
          analyticsIds: ['data-analytics', 'aria-label', 'data-testid'],
        },
        customEventsObject: [
          {
            eventType: 'page-view',
            eventProperties: {
              microapps,
              env,
            },
          },
        ],
        plugins: {
          amplitude: {
            // defaultTracking: true,
            projectId: '',
            apiKey: API_KEY,
          },
        },
      };
      userProfileObject = {
        email: username || '',
      };
    }
    return (
      <>
        {this.props.getUserInfoData && this.props.getUserInfoData.username ? (
          <AutoAnalyticsProvider
            analyticsConfig={analyticsConfigDev}
            userProfileObject={userProfileObject}
          />
        ) : null}
        <ScrollContainer>
          <Fixed>
            <WelcomeBanner />
            <UnsupportedBrowserBanner />
            <FullAccessBanner />
            <Banners />
            <PrimaryNavBar />
            <DemoRole />
          </Fixed>
          <Scrollable>
            <Suspense fallback={<Spinner />}>
              <Router />
            </Suspense>
          </Scrollable>
        </ScrollContainer>
        <InventoryReporter />

        <FlashNotification />
        <LegacyHashRouteAdapter />
        <HistoryAwareLinkAdapter />
        <NavFeatureTour />
      </>
    );
  }
}

AppContainer.propTypes = {
  loadPreferences: PropTypes.func.isRequired,
  fetchBootstrap: PropTypes.func,
  fetchIamSession: PropTypes.func,
  fetchCurrentUserUserTenants: PropTypes.func,
  loadingBootstrap: PropTypes.bool,
  loadedBootstrap: PropTypes.bool,
  loadingSession: PropTypes.bool,
  loadedSession: PropTypes.bool,
  loadingCurrentUserUserTenants: PropTypes.bool,
  loadedCurrentUserUserTenants: PropTypes.bool,
  alreadyFetchedPreferences: PropTypes.bool,
  loadingActiveWhatsNew: PropTypes.bool,
  loadedActiveWhatsNew: PropTypes.bool,
  fetchActiveWhatsNew: PropTypes.func.isRequired,
  loadingMaintenance: PropTypes.bool,
  loadedMaintenance: PropTypes.bool,
  fetchMaintenance: PropTypes.func.isRequired,
  getUserInfoData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  overallState: state, // Do not remove this line! It seems like it is not used, but it is by 3PAR navigation.
  alreadyFetchedPreferences: alreadyFetchedPreferences(state),
  ...bootstrapSelector(state),
  ...sessionSelector(state),
  ...sessionSelector(state),
  ...currentUserUserTenantsSelector(state),
  ...activeWhatsNewSelector(state),
  getUserInfoData: getUserInfo(state),
});

export default connect(mapStateToProps, {
  loadPreferences,
  fetchBootstrap,
  fetchIamSession,
  fetchCurrentUserUserTenants,
  fetchActiveWhatsNew,
  fetchMaintenance,
})(AppContainer);
