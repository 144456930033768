import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Layer } from 'grommet/es6';
import { Menu, Close } from 'grommet-icons';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { debounce } from 'underscore';
import { useSelector, useDispatch } from 'react-redux';
import ScrollContainer from '@infosight/elmer/esm/components/ScrollContainer';
import { TourBeacon } from '@infosight/elmer/esm/components/TourBeacon';
import {
  FRESH_OPEN,
  MENU_CONTEXT,
  NAV_MODAL_OPEN,
  navContexts,
} from '../constants';
import {
  contextSelector,
  modalOpenSelector,
  closeOnOutsideClickSelector,
  saveNavMenu,
  baseSelector as navBaseSelector,
  microappHomeSelector,
  freshOpenSelector,
} from '../reducer';
import { checkReloadRequired } from '../../extensibility/orchestrator/utils';
import { orchestratorDataSelector } from '../../extensibility/orchestrator/reducer';
import InAppNav from './InAppNav';
import MicroappSelector from './MicroappSelector';
import { bannersSelector } from '../../extensibility/banner/reducer';
import style from './NavMenu.scss';
import { setLastActive } from '../actionCreators';
import { getMicroappByPath, getParentMicroapp } from '../../router/knownRoutes';

const cx = classnames.bind(style);

const NavMenu = ({
  microapps,
  settings,
  resources,
  navRef,
  loading,
  history,
}) => {
  // We have to use initial values here, but the height will get overwritten after the first render and after any
  // window resizes with a value that is not a "magic number" based on the typical height of the nav bar
  const [dimensions, setDimensions] = useState({
    fillHeight: window.innerHeight - 90,
    maxWidth: window.innerWidth / 3,
  });

  function calculateAndSetDimensions(
    navRefParam,
    dimensionsParam,
    setDimensionsFn
  ) {
    if (navRefParam && navRefParam.current) {
      const heightOffset =
        navRefParam.current.offsetHeight +
        navRefParam.current.getBoundingClientRect().top;
      if (dimensionsParam.fillHeight !== window.innerHeight - heightOffset) {
        setDimensionsFn({
          fillHeight: window.innerHeight - heightOffset,
          maxWidth: window.innerWidth / 3,
        });
      }
    }
  }

  const dispatch = useDispatch();
  const modalOpen = useSelector(modalOpenSelector);
  const closeOnOutsideClick = useSelector(closeOnOutsideClickSelector);
  const setModalOpen = (open) => {
    dispatch({
      type: NAV_MODAL_OPEN,
      payload: open,
    });
  };

  // need to recalculate the placement when a banner is added while already open
  const banners = useSelector(bannersSelector);

  // Correct the dimensions of the NavMenu after rendering if they are not correct.
  // Typically, this is because we don't know about whether a banner exists yet when we initialize this component.
  // See https://nimblejira.nimblestorage.com/browse/IS-36991
  useEffect(() => {
    calculateAndSetDimensions(navRef, dimensions, setDimensions);
  }, [navRef, dimensions, modalOpen, banners]);

  // Set the dimensions of the NavMenu when the window is resized
  useEffect(() => {
    const resizeMe = debounce(() => {
      calculateAndSetDimensions(navRef, dimensions, setDimensions);
    }, 200);

    window.addEventListener('resize', resizeMe);
    // backstop for localhost since webpack will do the window sizing before things are actually loaded
    // returning a too small innerHeight
    window.addEventListener('DOMContentLoaded', resizeMe);
    return () => {
      window.removeEventListener('resize', resizeMe);
      window.removeEventListener('DOMContentLoaded', resizeMe);
    };
  }, [navRef, dimensions]);

  const closeModal = () => setModalOpen(false);

  const freshOpen = useSelector(freshOpenSelector);
  const setFreshOpen = (value) => {
    dispatch({
      type: FRESH_OPEN,
      payload: value,
    });
  };

  const menuContext = useSelector(contextSelector);
  const setMenuContext = (context, microapp) => {
    dispatch({
      type: MENU_CONTEXT,
      payload: { context, microapp },
    });
    setFreshOpen(false);
  };

  // baseSelectors are rarely used but we use it to save this state to localstorage on a reload
  const fullNavState = useSelector(navBaseSelector);
  const orchestrator = useSelector(orchestratorDataSelector);
  const getMicroappHome = useSelector(microappHomeSelector);

  const getMenuView = () => {
    if (freshOpen) {
      const orgPrefixRe = /^\/org\/[^/]*\//;
      const noOrgPathname = window.location.pathname.replace(orgPrefixRe, '/');
      const currentMicroapp = getMicroappByPath(noOrgPathname);
      const appId = getParentMicroapp(currentMicroapp);
      setMenuContext(navContexts.MICROAPP, appId);
    }

    const microapp =
      menuContext.microapp && microapps[menuContext.microapp]
        ? microapps[menuContext.microapp]
        : null;

    const isOnePageMicroapp = !!microapp && microapp.elements.length === 1;
    if (
      (menuContext.context === navContexts.BASE &&
        Object.keys(microapps).length > 0) ||
      !microapp ||
      isOnePageMicroapp
    ) {
      const goToMicroapp = (appId) => {
        const home = getMicroappHome(appId);
        if (checkReloadRequired(appId, orchestrator)) {
          const microappNav = {
            ...fullNavState,
            viewContext: { context: navContexts.MICROAPP, microapp: appId },
            lastActive: { url: home, microapp: appId },
            lastMicroapp: appId,
          };
          saveNavMenu(microappNav);
          window.location = home;
        } else {
          history.push(home);
        }
        setMenuContext(navContexts.MICROAPP, appId);
        setLastActive(home, appId);
        closeModal();
      };
      return (
        <MicroappSelector
          microappMap={microapps}
          settings={settings}
          resources={resources}
          goToMicroapp={goToMicroapp}
          close={closeModal}
          loading={loading}
          history={history}
        />
      );
    }

    return (
      <InAppNav
        appId={menuContext.microapp}
        title={microapp.title}
        elements={microapp.elements}
        childAppIds={microapp.childAppIds}
        back={() => setMenuContext(navContexts.BASE, null)}
        close={closeModal}
      />
    );
  };

  return (
    <>
      <Button
        title="Main Menu"
        className={classnames(cx('open-button'))}
        open={modalOpen}
        onClick={() => {
          if (!modalOpen) {
            calculateAndSetDimensions(navRef, dimensions, setDimensions);
          }
          setModalOpen(!modalOpen);
          setFreshOpen(!modalOpen);
        }}
        icon={
          <TourBeacon
            anchor="top-right"
            tour={{ appId: 'shell', name: 'New Nav Tour' }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Menu color="white" data-tour-step="nav-button" />
          </TourBeacon>
        }
        data-testid="main-menu"
      />
      {modalOpen && (
        <Layer
          animation="fadeIn"
          position="bottom-left"
          onEsc={() => setModalOpen(false)}
          onClickOutside={() => closeOnOutsideClick && setModalOpen(false)}
          responsive={false}
          plain
          style={{
            height: `${dimensions.fillHeight}px`,
            maxWidth: `${dimensions.maxWidth}px`,
          }}
        >
          <ScrollContainer className={classnames(cx('scroll'))}>
            <div className={classnames(cx('close-button'))}>
              <Button
                title="Main Menu"
                onClick={() => setModalOpen(false)}
                icon={<Close color="white" />}
                data-testid="main-menu-close"
                data-tour-step="nav-exit"
              />
            </div>
            <ScrollContainer.Scrollable>
              {getMenuView()}
            </ScrollContainer.Scrollable>
          </ScrollContainer>
        </Layer>
      )}
    </>
  );
};

NavMenu.propTypes = {
  microapps: PropTypes.object.isRequired,
  settings: PropTypes.array.isRequired,
  resources: PropTypes.array.isRequired,
  navRef: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(NavMenu);
