import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import autobind from 'react-autobind';
import GlobalBanner, {
  WARNING,
} from '@infosight/elmer/dist/components/GlobalBanner';
import { FaTimes } from 'react-icons/fa';
import classnames from 'classnames/bind';
import { isInternalDemoUserSelector } from '../user/reducer';
import { disableDialog } from '../preferences/actionCreators';
import { isDialogEnabled } from '../preferences/reducer';
import style from './style.scss';

const cx = classnames.bind(style);

const FULL_ACCESS_BANNER_ID = 'fullAccessBanner';

class FullAccessBanner extends Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = {
      willDismissPermanently: false,
      dismissed: false,
    };
  }

  handleCheckboxChange(event) {
    this.setState({
      willDismissPermanently: event.target.checked,
    });
  }

  dismiss() {
    const { dismissPermanently } = this.props;
    const { willDismissPermanently } = this.state;
    this.setState({
      dismissed: true,
    });
    if (willDismissPermanently) {
      dismissPermanently(FULL_ACCESS_BANNER_ID);
    }
  }

  render() {
    const { isInternalDemoUser, isOnFullAccess, isBannerEnabled } = this.props;
    const { dismissed, willDismissPermanently } = this.state;
    if (!isBannerEnabled || dismissed || !isInternalDemoUser) {
      return null;
    }

    /* eslint-disable jsx-a11y/label-has-for */
    return (
      <GlobalBanner
        severity={WARNING}
        content={
          <div
            className={cx(
              'full-access-banner',
              'flex-container',
              'align-justify'
            )}
          >
            <div className="flex-child-auto">
              You currently only have access to a small set of demo
              systems/accounts. To request access to all systems/accounts,{' '}
              {isOnFullAccess ? (
                'follow instructions below'
              ) : (
                <strong>
                  <Link to="/user/fullAccess">click here</Link>
                </strong>
              )}
              .
            </div>
            <div className="flex-child-shrink">
              <input
                type="checkbox"
                id="full-access-banner-checkbox"
                checked={willDismissPermanently}
                onChange={this.handleCheckboxChange}
              />
              <label htmlFor="full-access-banner-checkbox">
                Dismiss permanently
              </label>
              <button onClick={this.dismiss} type="button">
                <FaTimes />
              </button>
            </div>
          </div>
        }
      />
    );
    /* eslint-enable jsx-a11y/label-has-for */
  }
}

FullAccessBanner.propTypes = {
  // injected by connect
  dismissPermanently: PropTypes.func,

  isInternalDemoUser: PropTypes.bool,
  isOnFullAccess: PropTypes.bool,
  isBannerEnabled: PropTypes.bool,
};

const mapStateToProps = (state, { location }) => ({
  isInternalDemoUser: isInternalDemoUserSelector(state),
  isOnFullAccess: location.pathname.endsWith('/user/fullAccess'),
  isBannerEnabled: isDialogEnabled(state, FULL_ACCESS_BANNER_ID),
});

const mapDispatchToProps = {
  dismissPermanently: disableDialog,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FullAccessBanner)
);
