import { Component, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'react-autobind';
import { addRouter, removeRouter } from '@infosight/shell-api/lib/Router';
import { onUserContextChange } from '@infosight/shell-api/lib/UserProfile';
import { buildUrl } from '@infosight/elmer/dist/utils/url';
import { pageBoundaryRouteRenderer } from '@infosight/elmer/dist/page';
import { experimentalSelector } from '../../bootstrapper/reducer';
import { microappData } from '../../router/knownRoutes';

const HomePageContainer = lazy(() => import('../components/HomePageContainer'));
const LandingPageContainer = lazy(() =>
  import('../components/LandingPageContainer')
);
const AlertPage = lazy(() => import('../components/AlertPage'));
const WhatsNewPage = lazy(() => import('../components/WhatsNewPage'));
const DevelopersPage = lazy(() => import('../components/DevelopersPage'));
const NimbleUserRegistration = lazy(() =>
  import(
    '../../registration/components/UserRegistration/NimbleUserRegistration'
  )
);
const UnifiedAssetDashboard = lazy(() =>
  import('../../unifiedAssetDashboard/components/UnifiedAssetDashboard')
);

class RouterExtension extends Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidMount() {
    this.handleUpdate();
    this.unsubscribe = onUserContextChange(this.handleUpdate);
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleUpdate() {
    const { experimental } = this.props;
    // This is left here to make it easy for others to use it when needed
    console.debug('Experimental mode:', experimental);
    // Everything is always here so we can react to changes
    [
      {
        url: buildUrl('landing'),
        router: pageBoundaryRouteRenderer(LandingPageContainer, 'Welcome'),
        exact: false,
        appId: 'welcome',
      },
      {
        url: buildUrl('home'),
        router: pageBoundaryRouteRenderer(HomePageContainer, 'Home'),
        exact: false,
        appId: 'welcome',
      },
      {
        url: buildUrl('activeBulletins'),
        router: pageBoundaryRouteRenderer(AlertPage, 'Bulletins'),
        exact: false,
        appId: 'welcome',
      },
      {
        url: buildUrl('developers'),
        router: pageBoundaryRouteRenderer(DevelopersPage, 'Developers'),
        exact: false,
        appId: 'welcome',
      },
      {
        url: buildUrl('registration/nimble'),
        router: pageBoundaryRouteRenderer(
          NimbleUserRegistration,
          `${microappData.nimble.title} User Registration`
        ),
        exact: false,
        appId: 'welcome',
      },
      {
        url: buildUrl('unifiedAssets'),
        router: pageBoundaryRouteRenderer(
          UnifiedAssetDashboard,
          `Unified Asset Dashboard`
        ),
        exact: false,
        appId: 'unifiedAssets',
      },
      {
        url: buildUrl('whatsNew'),
        router: pageBoundaryRouteRenderer(WhatsNewPage, "What's New"),
        exact: false,
        appId: 'welcome',
      },
    ].forEach((extension) => {
      if (extension.router) {
        addRouter(extension);
      } else {
        removeRouter(extension);
      }
    });
  }

  render() {
    return null;
  }
}

RouterExtension.propTypes = {
  experimental: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  experimental: experimentalSelector(state),
});

export default connect(mapStateToProps)(RouterExtension);
