import { useEffect } from 'react';
import { withRouter } from 'react-router';
import Shepherd from 'shepherd.js';
import { registerTour, startTour } from '@infosight/shell-api/lib/Tour';
import PropTypes from 'prop-types';
import createNewNavTour from '../tour/createNewNavTour';
import { CONTINUE_NAV_TOUR_LS } from '../tour/constants';

// the more modern way to do this is to make it into a hook, but the react-router hooks we would need are not available in react-router@4
// and also the parent component is a class component which cannot use hooks anyways
const NavFeatureTour = ({ history, match }) => {
  useEffect(() => {
    const navTourOpts = createNewNavTour(history, match);
    // we check localstorage to see if there is a nav tour to continue after triggering a sandboxing refresh
    registerTour(navTourOpts);
    const continueTour =
      window.localStorage.getItem(CONTINUE_NAV_TOUR_LS) === 'true';
    if (continueTour) {
      // if we are continuing a tour, we need to delete that key and then go to the next step
      window.localStorage.removeItem(CONTINUE_NAV_TOUR_LS);
      // this event triggers before activeTour is defined, so we have to do a setTimeout here
      setTimeout(() => {
        Shepherd.activeTour.show('navTourStart');
      }, 100);
      startTour(navTourOpts);
    }
    // empty useEffect dependency list because we only ever want to register this stuff once
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return null;
};

NavFeatureTour.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    listen: PropTypes.func.isRequired,
    // Add other properties of history as needed
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
    isExact: PropTypes.bool.isRequired,
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    // Add other properties of match as needed
  }).isRequired,
};

export default withRouter(NavFeatureTour);
