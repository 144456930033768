import {
  URL_UPDATE,
  TITLE_UPDATE,
  URL_LOOKBACK,
  INTERNAL_REALM,
  AUTHORITIES,
} from './constants';
import { FETCH_BOOTSTRAP_SUCCEEDED } from '../bootstrapper/constants';

export const initialState = {
  permissions: null,
  historyList: [],
};

const ACTION_HANDLERS = {
  [FETCH_BOOTSTRAP_SUCCEEDED]: (state, { payload }) => ({
    ...state,
    ...payload.user,
  }),
  [URL_UPDATE]: (state, { payload: { path, action, title } }) => {
    let histList = state.historyList || [];
    const historyItem = { path, title };
    if (action === 'REPLACE') {
      histList = histList.slice(0, -1).concat(historyItem);
    } else {
      histList = histList.concat(historyItem);
    }
    if (histList.length > URL_LOOKBACK) {
      histList = histList.slice(1, URL_LOOKBACK + 1);
    }
    return {
      ...state,
      historyList: histList,
    };
  },
  [TITLE_UPDATE]: (state, { payload: { title } }) => {
    const histList = state.historyList || [];
    if (histList.length > 0) {
      histList[histList.length - 1].title = title;
    }
    return {
      ...state,
      historyList: histList,
    };
  },
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

// Selectors
const sfrTenantRegex = /^urn:sfr:/;
export const sfrTenantsSelector = (state) => {
  if (state.iam && state.iam.session && state.iam.session.claimTags) {
    return state.iam.session.claimTags
      .filter((e) => e.match(sfrTenantRegex))
      .map((e) => e.replace(sfrTenantRegex, ''));
  }
  return [];
};
const nimbleTenantRegex = /^urn:nimble:/;
// using the claim tags isn't the best way of determining this, but without loading nimble and making calls to its backend it's what we have
// it should work most of the time as long as the data is clean
export const nimbleTenantSelector = (state) => {
  let tags = 0;
  if (state.iam && state.iam.session && state.iam.session.claimTags) {
    tags = state.iam.session.claimTags.filter((e) =>
      e.match(nimbleTenantRegex)
    ).length;
  }
  return tags > 0;
};
export const userSelector = (state) => state.user;
export const userWithIAMSelector = (state) => {
  const session = state && state.iam && state.iam.session;
  if (session) {
    session.sfrTenants = sfrTenantsSelector(state);
  }
  const user = state ? state.user : {};

  return { ...user, session };
};

export const toSaveSelector = (state) => ({
  historyList: userSelector(state).historyList,
});

export const permissionsSelector = (state) => userSelector(state).permissions;
const sessionSelector = (state) => state.iam && state.iam.session;
export const isInternalUserSelector = (state) =>
  sessionSelector(state)
    ? sessionSelector(state).userRealm === INTERNAL_REALM
    : false;
export const isInternalUserFromSession = (session) =>
  session && session.userRealm === INTERNAL_REALM;

export const isInternalDemoUserSelector = (state) => {
  const session = sessionSelector(state);
  return !!(
    session &&
    session.demoOnlyAccess &&
    isInternalUserFromSession(session)
  );
};

export const hasSiteAccessSelector = (state) => !!sessionSelector(state);

// we want the second to last element
export const previousUrlForSupportSelector = (state) =>
  userSelector(state).historyList[
    Math.max(0, userSelector(state).historyList.length - 2)
  ];

export const recentPagesSelector = (state) => userSelector(state).historyList;

export const isSiteAdminSelector = (state) =>
  userSelector(state).authorities &&
  userSelector(state).authorities.includes(AUTHORITIES.SITE_ADMIN);
