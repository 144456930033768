// (C) Copyright 2018 Hewlett Packard Enterprise Development LP

import { Component } from 'react';
import autobind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import { addMenu, removeMenu } from '@infosight/shell-api/lib/PrimaryNav';
import { AuthorizationEvaluator } from '../../../user';

class SimplivityAnalyticsPrimaryNavExtension extends Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidMount() {
    this.handleUpdate();
  }

  handleUpdate() {
    // Everything is always here so we can react to changes
    const extension = {
      menuId: 'resources',
      id: 'simplivity-analytics',
      content: this.getResources(),
    };
    if (extension.content) {
      addMenu(extension);
    } else {
      removeMenu(extension);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getResources() {
    const simplivity = AuthorizationEvaluator.filter([
      {
        id: 'simplivity-analytics',
        title: 'HPE SimpliVity',
        depth: 1,
        options: AuthorizationEvaluator.filter([
          {
            id: 'support',
            title: 'Support',
            url: 'https://support.hpe.com/connect/s/viewcases',
            nodeType: 'external',
          },
          {
            id: 'started',
            title: 'Getting Started',
            url: 'https://support.hpe.com/hpsc/doc/public/display?docId=a00089726en_us',
            nodeType: 'external',
          },
          {
            id: 'documentation',
            title: 'Documentation',
            url: 'https://support.hpe.com/hpesc/public/km/search#q=HPE%20%20OmniStack&t=Documents&sort=relevancy&layout=table&numberOfResults=25&f:@kmdoclanguagecode=[cv1871440]&f:@contenttype=[Documents]&hpe=1',
            nodeType: 'external',
          },
        ]),
      },
    ]);

    if (!simplivity.length) {
      return null;
    }
    return simplivity;
  }

  render() {
    return null;
  }
}

export default withRouter(SimplivityAnalyticsPrimaryNavExtension);
