import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateURL, updateTitle } from '../user/actionCreators';
import { iamSessionSelector, customerNameSelector } from '../iam/reducer';
// New Relic comment import { getMicroappByPath, microappData } from '../router/knownRoutes';

class HistoryListener extends Component {
  componentDidMount() {
    // Setting something that can be tested
    this.interceptorEnabled = false;
    // this.setupNewRelicInterceptor(); // Commented out New Relic interceptor setup

    this.unlisten = this.props.history.listen((location, action) => {
      this.props.updateURL(
        `${location.pathname}${location.search}${location.hash}`,
        action,
        document.title
      );
    });

    // select the target node
    const titleElement = document.querySelector('title');

    // create an observer instance
    const observer = new MutationObserver((mutations) => {
      // We need only first event and only new value of the title
      if (
        mutations[0].target.childNodes &&
        mutations[0].target.childNodes[0] &&
        mutations[0].target.childNodes[0].nodeValue
      ) {
        const newTitle = mutations[0].target.childNodes[0].nodeValue;
        this.props.updateTitle(newTitle);
      } else {
        this.props.updateTitle(null);
      }
    });

    // configuration of the observer:
    const config = { subtree: true, characterData: true, childList: true };

    // pass in the target node, as well as the observer options
    observer.observe(titleElement, config);
  }

  componentWillUnmount() {
    this.unlisten();
    // this.removeNewRelicInterceptor(); // Commented out New Relic interceptor removal
  }

  /**
   * New Relic setup and interception logic is commented out
   */
  /*
  setupNewRelicInterceptor() {
    if (!window.newrelic) {
      return;
    }

    this.originalPushState = window.history.pushState;
    this.originalReplaceState = window.history.replaceState;

    const wrap = (original) => {
      const wrapped = (state, title, url, ...args) => {
        const { userRealm, userName, customer, claimTags } = this.props;
        let microapp = getMicroappByPath(url);
        if (__UNIT_TEST__) {
          // This gets around a JSDOM limitation. It is removed from production code
          microapp = getMicroappByPath(url.replace('about:blank', ''));
        }
        if (microappData[microapp] && microappData[microapp].isCore) {
          microapp = 'core';
        }

        [
          ['internalOrExternal', userRealm],
          ['microapp', microapp],
          ['username', userName],
          ['customerDomain', customer],
          [
            'newOrReturning',
            claimTags && claimTags.length ? 'RETURNING' : 'NEW',
          ],
        ].forEach(([name, value]) => {
          window.newrelic.interaction().setAttribute(name, value);
        });

        original.call(window.history, state, title, url, ...args);
      };

      // eslint-disable-next-line no-underscore-dangle
      wrapped.__INFOSIGHT__ = true;
      return wrapped;
    };

    window.history.pushState = wrap(window.history.pushState);
    window.history.replaceState = wrap(window.history.replaceState);

    // Setting something that can be tested
    this.interceptorEnabled = true;
  }

  removeNewRelicInterceptor() {
    if (this.originalPushState) {
      window.history.pushState = this.originalPushState;
    }
    if (this.originalReplaceState) {
      window.history.replaceState = this.originalReplaceState;
    }
  }
  */

  render() {
    return null;
  }
}

HistoryListener.propTypes = {
  // Injected by withRouter
  history: PropTypes.object,
  updateURL: PropTypes.func.isRequired,
  updateTitle: PropTypes.func.isRequired, // eslint-disable-line

  // newrelic comment userRealm: PropTypes.string,
  // userName: PropTypes.string,
  // customer: PropTypes.string,
  // claimTags: PropTypes.arrayOf(PropTypes.string.isRequired),
};

// newrelic comment HistoryListener.defaultProps = {
//   userRealm: undefined,
//   userName: undefined,
//   customer: undefined,
// };

export const Unwrapped = HistoryListener;
const mapStateToProps = (state) => ({
  ...(iamSessionSelector(state) || {}),
  customer: customerNameSelector(state),
});
export default withRouter(
  connect(mapStateToProps, {
    updateURL,
    updateTitle,
  })(HistoryListener)
);
