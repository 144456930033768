import { actionNamespacer } from '@infosight/elmer/dist/utils/redux';

export const navContexts = {
  BASE: 'base',
  MICROAPP: 'microapp',
};

export const iconSize = '18px';

const ns = actionNamespacer('NAV_MENU');
export const MENU_CONTEXT = ns('MENU_CONTEXT');
export const FRESH_OPEN = ns('FRESH_OPEN');
export const LAST_ACTIVE = ns('LAST_ACTIVE');
export const NAV_MODAL_OPEN = ns('NAV_MODAL_OPEN');
export const ACTIVE_PANELS = ns('ACTIVE_PANELS');
export const RESET_ACTIVE_PANELS = ns('RESET_ACTIVE_PANELS');
export const RESET_MENU = ns('RESET_MENU');
export const NAV_CLOSE_ON_OUTSIDE_CLICK = ns('NAV_CLOSE_ON_OUTSIDE_CLICK');
