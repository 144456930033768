import { isInternalUserSelector, hasSiteAccessSelector } from '../user/reducer';
import { inventorySelector } from '../inventory/reducer';
import { sessionSelector } from '../iam/reducer';

// eslint-disable-next-line import/prefer-default-export
export const shouldShowWelcomePageSelector = (state) =>
  hasSiteAccessSelector(state) &&
  !isInternalUserSelector(state) &&
  inventorySelector(state).inventory &&
  !Object.values(inventorySelector(state).inventory).some(
    ({ presence }) => presence
  );

export const getUserInfo = (state) => {
  if (state && state.user && state.user.username && state.inventory.inventory) {
    const inventoryIn = state.inventory.inventory;
    const inventory = Object.keys(inventoryIn)
      .filter((key) => inventoryIn[key].presence)
      .join(',');
    return {
      username: state.user.username,
      microapps: inventory,
    };
  }
  return null;
};

export const shouldWaitSelector = (state) => {
  // wait if things are still loading
  // For most of these we check if it is either loading now or has not loaded at all yet (where an error is considered loaded)

  // We care about the session because it tells us whether the user has set a landing page or not
  const sessionLoading =
    sessionSelector(state).loadingSession ||
    !sessionSelector(state).loadedSession;

  // We care about the inventory because it tells us whether to show the home page or the welcome page
  const inventoryLoading =
    inventorySelector(state).loadingInventory ||
    !(
      inventorySelector(state).loadedInventory ||
      inventorySelector(state).errorInventory
    );

  return sessionLoading || inventoryLoading;
};
