import {
  createReducersForAction,
  createSelector,
  // getInitialState,
} from '@infosight/elmer/dist/utils/redux';

import {
  REGISTRATION_STARTED,
  REGISTRATION_FAILED,
  REGISTRATION_SUCCEEDED,
  REQUEST_ACCESS_STARTED,
  REQUEST_ACCESS_FAILED,
  REQUEST_ACCESS_SUCCEEDED,
  STATUS,
  RESET,
  REGISTRATION_SUCCESS_MESSAGE,
  getRequestAccessSuccessMessage,
  REGISTRATION_RESET,
  REQUEST_ACCESS_RESET,
  RESEND_CLAIM_TOKEN_RESET,
  FETCH_CONTACTS,
  RESEND_CLAIM_TOKEN,
} from './constants';

export const registrationInitialState = {
  registration: {
    status: undefined,
    message: undefined,
    tenantId: undefined,
    claimToken: undefined, // TODO: Is this needed ??
  },
};
export const resendClaimTokenInitialState = {
  resendClaimToken: {
    status: undefined,
  },
};
export const requestAccessInitialState = {
  requestAccess: {
    status: undefined,
    message: undefined,
    caseNumber: undefined,
    arraySn: undefined,
  },
};
export const initialState = {
  registrationInitialState,
  requestAccessInitialState,
};

const ACTION_HANDLERS = {
  [RESET]: () => initialState,
  [REGISTRATION_STARTED]: (state, { payload: { claimToken } }) => ({
    ...state,
    registration: {
      status: STATUS.IN_PROGRESS,
      claimToken,
    },
  }),
  [REGISTRATION_SUCCEEDED]: (state, { payload: { tenantId } }) => ({
    ...state,
    registration: {
      status: STATUS.SUCCEEDED,
      tenantId,
      message: REGISTRATION_SUCCESS_MESSAGE,
    },
  }),
  [REGISTRATION_FAILED]: (state, { payload: { message } }) => ({
    ...state,
    registration: {
      status: STATUS.FAILED,
      message,
    },
  }),
  [REGISTRATION_RESET]: (state) => ({
    ...state,
    ...registrationInitialState,
  }),
  [REQUEST_ACCESS_STARTED]: (state, { payload: { arraySn } }) => ({
    ...state,
    requestAccess: {
      status: STATUS.IN_PROGRESS,
      arraySn,
    },
  }),
  [REQUEST_ACCESS_SUCCEEDED]: (
    state,
    { payload: { arraySn, caseNumber } }
  ) => ({
    ...state,
    requestAccess: {
      status: STATUS.SUCCEEDED,
      caseNumber,
      arraySn,
      message: getRequestAccessSuccessMessage({ caseNumber, arraySn }),
    },
  }),
  [REQUEST_ACCESS_FAILED]: (state, { payload: { message } }) => ({
    ...state,
    requestAccess: {
      status: STATUS.FAILED,
      message,
    },
  }),
  [REQUEST_ACCESS_RESET]: (state) => ({
    ...state,
    ...requestAccessInitialState,
  }),
  [RESEND_CLAIM_TOKEN_RESET]: (state) => ({
    ...state,
    ...resendClaimTokenInitialState,
  }),
  ...createReducersForAction({
    type: FETCH_CONTACTS,
    stateKey: 'contacts',
  }),
  ...createReducersForAction({
    type: RESEND_CLAIM_TOKEN,
    stateKey: 'resendClaimToken',
  }),
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

const baseSelector = (state) => state.registration;

export const reset = () => ({ type: RESET });

export const registrationStatusSelector = createSelector(
  baseSelector,
  'registration'
);

export const requestAccessStatusSelector = createSelector(
  baseSelector,
  'requestAccess'
);

export const resendClaimTokenStatusSelector = createSelector(
  baseSelector,
  'resendClaimToken'
);

export const contactsSelector = createSelector(baseSelector, 'contacts');
