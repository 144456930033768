import { actionNamespacer } from '@infosight/elmer/dist/utils/redux';

const ns = actionNamespacer('TOUR');
export const RESET = ns('RESET');

export const TOUR_USER_PROPERTY_PREFIX = 'urn:shell:tour:';

export const USER_TOUR_STATUS = {
  SNOOZED: 'SNOOZED',
  STARTED: 'STARTED',
  HIDDEN: 'HIDDEN',
  EARLY_EXIT: 'EARLY_EXIT',
  COMPLETED: 'COMPLETED',
};

export const CONTINUE_NAV_TOUR_LS = ns('continueNavTourLS');

export const SNOOZE_HOURS = 48;

export const FOOTER_TYPE = {
  INTRO: 'intro',
  BIDIRECTIONAL: 'bidirectional',
  FORWARD_ONLY: 'forward-only',
  CONCLUSION: 'conclusion',
  CONCLUSION_ONLY: 'conclusion-only',
};

export const INTERACTION = {
  /**
   * When the user clicks the beacon and the intro screen is displayed
   */
  OFFERED: 'shell.Tour Offered',

  /**
   * The user clicks the "Take a Tour" button to advance to the next step, which to the user is the first step of the tour
   */
  STARTED: 'shell.Tour Started',

  /**
   * The user has started the tour, but the beacon show be hidden for aesthetic reasons.
   */
  HIDDEN: 'shell.Tour Started',

  /**
   * The user clicks the "Snooze" button to defer the tour till later
   */
  SNOOZED: 'shell.Tour Snoozed',

  /**
   * The user clicks the "No" button - This is a placeholder for completeness of thought
   */
  REJECTED: 'shell.Tour Rejected',

  /**
   * The user completes the tour by clicking the "Finish" button or the Exit icon on the last step
   */
  COMPLETED: 'shell.Tour Completed',

  /**
   * The user clicks the Exit icon at any point between the first and last step
   */
  EARLY_EXIT: 'shell.Tour Exited Early',
};
