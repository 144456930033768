import {
  actionNamespacer,
  getStartedActionType,
  getSuccessActionType,
  getErrorActionType,
} from '@infosight/elmer/dist/utils/redux';
import { microappData } from '../router/knownRoutes';

const ns = actionNamespacer('REGISTRATION');

export const RESET = ns('RESET');

/**
 * Register to an InfoSight Org. using Claim Token
 * @type {string}
 */
export const REGISTRATION = ns('REGISTER');
export const REGISTRATION_STARTED = getStartedActionType(REGISTRATION);
export const REGISTRATION_SUCCEEDED = getSuccessActionType(REGISTRATION);
export const REGISTRATION_FAILED = getErrorActionType(REGISTRATION);
export const REGISTRATION_RESET = ns('REGISTRATION_RESET');

/**
 * Request Access to InfoSight Org. using Array Sn
 * @type {string}
 */
export const REQUEST_ACCESS = ns('REQUEST_ACCESS');
export const REQUEST_ACCESS_STARTED = getStartedActionType(REQUEST_ACCESS);
export const REQUEST_ACCESS_SUCCEEDED = getSuccessActionType(REQUEST_ACCESS);
export const REQUEST_ACCESS_FAILED = getErrorActionType(REQUEST_ACCESS);
export const REQUEST_ACCESS_RESET = ns('REQUEST_ACCESS_RESET');

export const FETCH_CONTACTS = ns('FETCH_CONTACTS');
export const FETCH_CONTACTS_STARTED = getStartedActionType(FETCH_CONTACTS);
export const FETCH_CONTACTS_SUCCEEDED = getSuccessActionType(FETCH_CONTACTS);
export const FETCH_CONTACTS_FAILED = getErrorActionType(FETCH_CONTACTS);

export const RESEND_CLAIM_TOKEN = ns('RESEND_CLAIM_TOKEN');
export const RESEND_CLAIM_TOKEN_STARTED =
  getStartedActionType(RESEND_CLAIM_TOKEN);
export const RESEND_CLAIM_TOKEN_SUCCEEDED =
  getSuccessActionType(RESEND_CLAIM_TOKEN);
export const RESEND_CLAIM_TOKEN_FAILED = getErrorActionType(RESEND_CLAIM_TOKEN);
export const RESEND_CLAIM_TOKEN_RESET = ns('RESEND_CLAIM_TOKEN_RESET');

export const MessageTypes = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const STATUS = {
  IN_PROGRESS: 'in_progress',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
};

export const DEFAULT_ERROR_MESSAGE =
  'An unexpected error occurred. Please try again after sometime. If the problem persists, please contact HPE InfoSight Support.';

export const REGISTRATION_SUCCESS_MESSAGE =
  'You have been successfully added to InfoSight Org. Please refresh the page if you are not redirected in 2 seconds.';

export const getRequestAccessSuccessMessage = ({ arraySn, caseNumber }) =>
  `Owner of ${arraySn} has been notified to grant you access. Use Case No: ${caseNumber} for any correspondence with HPE InfoSight Support`;

/**
 * Message to show while registration request is in progress
 * @type {string}
 */
export const REGISTRATION_IN_PROGRESS_MESSAGE =
  'You will be redirected to your Org. in next few seconds.';

/**
 * Message to show while request access is in progress
 * @param serialNumber
 * @return {string}
 */
export const getRequestAccessInprogressMessage = (serialNumber) =>
  `We are notifying the Owner of ${serialNumber}`;

export const CLAIM_TOKEN_VALIDATION_ERROR =
  'Invalid Claim Token, please provide valid Claim Token or ask you Administrator to send you again.';

export const ARRAY_SN_VALIDATION_ERROR = 'Invalid Array Serial Number';

/**
 * Emailing to this id will create an SFDC Case for InfoSight Support team
 * @type {string}
 */
export const INFOSIGHT_SUPPORT_EMAIL = 'infosightsupport@hpe.com';

/**
 * [InfoSight Portal Support] HPE Alletra 6000, Alletra 5000, Nimble Storage: User Registration Inquiry
 * @type {string}
 */
export const MAIL_TO_SUBJECT = encodeURIComponent(
  `[InfoSight Portal Support] ${microappData.nimble.title}: User Registration Inquiry`
);

export const CLAIM_TOKEN_ISSUER = 'infosight.iam';

export const HPE_COLOR = '#01A982';

export const RECIPIENTS_ERROR_MSG =
  'No contacts available, please add contacts via this link';

export const RESEND_CLAIM_TOKEN_SUCCESS_MSG =
  'Successfully sent the claim token';

export const RESEND_CLAIM_TOKEN_ERROR_MSG =
  'Error occurred while sending the claim token';
