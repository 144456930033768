import {
  ACTIVE_PANELS,
  NAV_MODAL_OPEN,
  RESET_MENU,
  NAV_CLOSE_ON_OUTSIDE_CLICK,
  LAST_ACTIVE,
} from './constants';
import { activePanelSelector, lastMicroappSelector } from './reducer';
import { getStore } from '../util/storeProvider';

// eslint-disable-next-line import/prefer-default-export
export const updateActivePanels =
  (key, activeIndexes) => (dispatch, getState) => {
    const activePanelStore = activePanelSelector(getState());
    activePanelStore[key] = activeIndexes;
    dispatch({
      type: ACTIVE_PANELS,
      payload: activePanelStore,
    });
  };

export const resetNavMenuForTour = () => {
  const lastMicroapp = lastMicroappSelector(getStore().getState());
  getStore().dispatch({ type: RESET_MENU, payload: { lastMicroapp } });
  getStore().dispatch({ type: NAV_CLOSE_ON_OUTSIDE_CLICK, payload: false });
};
export const openNavMenuForTour = () => {
  getStore().dispatch({ type: NAV_MODAL_OPEN, payload: true });
  getStore().dispatch({ type: NAV_CLOSE_ON_OUTSIDE_CLICK, payload: false });
};
export const closeNavMenuForTour = () => {
  getStore().dispatch({ type: NAV_MODAL_OPEN, payload: false });
  getStore().dispatch({ type: NAV_CLOSE_ON_OUTSIDE_CLICK, payload: true });
};
export const setLastActive = (url, microapp) => {
  getStore().dispatch({
    type: LAST_ACTIVE,
    payload: { url, microapp },
  });
};
