import { Component } from 'react';
// import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import autobind from 'react-autobind';
import { addMenu } from '@infosight/shell-api/lib/PrimaryNav';
import { onUserContextChange } from '@infosight/shell-api/lib/UserProfile';
import { MENUOPTIONS } from '../constants';
import { AuthorizationEvaluator } from '../../user';
// import { isInternalUserSelector } from '../../user/reducer';

class PrimaryNavExtension extends Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidMount() {
    this.handleUpdate();
    this.unsubscribe = onUserContextChange(this.handleUpdate);
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  /* eslint-disable class-methods-use-this */
  handleUpdate() {
    // const { isInternalUser } = this.props;
    const options = AuthorizationEvaluator.filter([
      {
        id: MENUOPTIONS.INFOSIGHT.ID,
        title: MENUOPTIONS.INFOSIGHT.TITLE,
        depth: 1,
        options: AuthorizationEvaluator.filter([
          {
            id: MENUOPTIONS.GETTING_STARTED.ID,
            title: MENUOPTIONS.GETTING_STARTED.TITLE,
            nodeType: 'external',
            url: MENUOPTIONS.GETTING_STARTED.URL,
          },
          {
            id: MENUOPTIONS.USER_GUIDE.ID,
            title: MENUOPTIONS.USER_GUIDE.TITLE,
            nodeType: 'external',
            url: MENUOPTIONS.USER_GUIDE.URL,
          },
          {
            id: MENUOPTIONS.SUPPORT.ID,
            title: MENUOPTIONS.SUPPORT.TITLE,
            nodeType: 'internal',
            url: MENUOPTIONS.SUPPORT.URL,
          },
          // {
          //   id: MENUOPTIONS.YAMMER.ID,
          //   title: MENUOPTIONS.YAMMER.TITLE,
          //   nodeType: 'external',
          //   url: MENUOPTIONS.YAMMER.URL,
          //   access: {
          //     custom: () => isInternalUser,
          //   },
          // },
          {
            id: MENUOPTIONS.INFOSIGHT_SERVICE_STATUS.ID,
            title: MENUOPTIONS.INFOSIGHT_SERVICE_STATUS.TITLE,
            nodeType: 'external',
            url: MENUOPTIONS.INFOSIGHT_SERVICE_STATUS.URL,
          },

          {
            id: MENUOPTIONS.STORAGE_SECURITY.ID,
            title: MENUOPTIONS.STORAGE_SECURITY.TITLE,
            nodeType: 'external',
            url: MENUOPTIONS.STORAGE_SECURITY.URL,
          },
          // {
          //   id: MENUOPTIONS.SERVERS_SECURITY.ID,
          //   title: MENUOPTIONS.SERVERS_SECURITY.TITLE,
          //   nodeType: 'external',
          //   url: MENUOPTIONS.SERVERS_SECURITY.URL,
          // },
        ]),
        collapseHeader: false,
      },
    ]);

    addMenu({
      menuId: MENUOPTIONS.RESOURCES.ID,
      id: MENUOPTIONS.RESOURCES.ID,
      content: options,
    });
  }

  render() {
    return null;
  }
}

// PrimaryNavExtension.propTypes = {
//   isInternalUser: PropTypes.bool,
// };

// const mapStateToProps = (state) => ({
//   isInternalUser: isInternalUserSelector(state),
// });

// export default connect(mapStateToProps)(PrimaryNavExtension);
export default PrimaryNavExtension;
