import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import shallowEqual from 'react-redux/lib/utils/shallowEqual';
import { isEqual, omit } from 'underscore';
import {
  activeTenantSelector,
  tenantSelectorVisibilitySelector,
  nextTenantAuthorizationSelector,
  sessionSelector,
} from '../reducer';
import {
  buildTenantScopedUrl,
  parseTenantIdentifier,
  getRootUrlForTenant,
} from '../services';
import TenancyActionExecutor from './TenancyActionExecutor'; // eslint-disable-line import/no-named-as-default
import { switchTenant } from '../actionCreators'; // eslint-disable-line import/no-named-as-default

const isSameTenant = (urnOrTenantId, otherUrnOrTenantId) =>
  parseTenantIdentifier(urnOrTenantId) ===
  parseTenantIdentifier(otherUrnOrTenantId);

/**
 * This aggregates URL state and redux application state and monitors changes to determine:
 * 1. whether or not the user's active tenant needs to be updated, and
 * 2. whether or not the URL should include the tenant
 *
 *
 * It does not make any changes to application state; instead an "Action" is generated and passed
 * to TenantActionExecutor, which is responsible for safely executing the action in a way that will not cause
 * an infinite loop or redirect loop
 *
 * To do this it evaluates different conditions to determine the next eventual state. The order of these conditions is very important, as
 * that allows us to write concise conditions. Each condition has a "Case #" that can be used to simplify debugging.
 *
 *
 * # Avoiding Infinite Loops
 * Because the browser does not update the URL in the same Javascript thread, we cannot rely on the event loop to prevent race conditions, nor can we expect
 * the typical run-to-completion processing we are used to. Hence, you will notice many comments and what seems like indirection to avoid infinite loops and
 * race conditions. Since actions are resolved using React's lifecycle, we try to minimize the render cycles.
 *
 * In the previous version (old TenantNamespace component ), the "reconciliation" would run on every Redux state change.
 * This was very fragile and difficult to change. It also followed the anti-pattern of having side effects in the componentWillRecieveProps function.
 *
 * Rather than create a plain-JS state and user observer, I still use React to collect all the needed state from redux and react-router.
 * You will see that react-redux's connect HOC is used to guard against unnecessary renders by implementing an infrequently used option (areOwnPropsEqual)
 * to check if the `location` prop (react-router) truly did change.
 * If an action is resolved, we compare it to the previous action, then store it iff there was a net difference.
 */
export class TenancyActionResolver extends Component {
  static resolveAction(prevProps, nextProps) {
    // Simplify testing of this function. This block is removed from the build
    if (process.env.BABEL_ENV === 'ci') {
      if (!prevProps) {
        prevProps = nextProps;
      }
    }

    const { tenant, location, errorNextTenantAuthorization } = nextProps;

    const requestedTenantUrn = nextProps.match.params.tenantId;
    const currentTenantUrn = prevProps.match.params.tenantId;

    const generateAction = (state) => ({
      location: null,
      tenant: null,
      noop: !state,
      ...(state || {}),
    });

    // CASE #1: Since the DefaultPage strips off the urn no matter what we do here,
    // we will do nothing when on the default page.
    const defaultPageEvaluator = () => {
      if (nextProps.location.pathname === '/') {
        return generateAction();
      }

      return undefined;
    };

    // CASE #2: When the URL is URN-based, it should be updated to the tenant-id-based URL
    const replaceUrnBasedUrl = () => {
      if (
        requestedTenantUrn &&
        (requestedTenantUrn.startsWith('urn:infosight:') ||
          requestedTenantUrn.startsWith('urn%3Ainfosight%3A'))
      ) {
        return generateAction({
          location: {
            ...location,
            pathname: location.pathname.replace(
              nextProps.match.url,
              getRootUrlForTenant(parseTenantIdentifier(requestedTenantUrn))
            ),
          },
        });
      }

      return undefined;
    };

    // CASE #3: Since the session is still loading, fallback to a mode where there is no tenant selection, but also do nothing WRT tenant selection
    const appLoadingEvaluator = () => {
      if (!nextProps.loadedSession) {
        return generateAction();
      }

      return undefined;
    };

    // CASE #4: No need for tenant selection behavior, since this user has 0..1 tenants.
    // If the URL contains a tenant and it is the same tenant as the one in store, it is then removed from the URL. Otherwise we switch to the new Tenant.
    const hideTenantSelectorEvaluator = () => {
      if (!nextProps.tenantSelectorVisible) {
        if (!requestedTenantUrn) {
          return generateAction();
        }

        // Say User 'A' sent a deep link to User 'B' but if the User 'B' doesn't have access to that Tenant given in deep link,
        // then SwitchTenant Action will fail.
        // Due to a Bug (not being redirected to error page showing that he doesn't access to that tenant page), the following first if-condition
        // is added to safeguard against infinite loop of switchTenant Actions.
        // Why will it cause infinite loop: Tenant in the store won't match with the one in URL because of a failed switchTenant Action.
        // and since the tenant in store still doesn't match with the one in URL it will trigger another TenantSwitch Action and so on.
        if (!errorNextTenantAuthorization) {
          if (
            !tenant ||
            (tenant && !isSameTenant(tenant.id, requestedTenantUrn))
          ) {
            return generateAction({
              tenantId: parseTenantIdentifier(requestedTenantUrn),
            });
          }

          return generateAction({
            location: {
              ...location,
              pathname: location.pathname.replace(nextProps.match.url, ''),
            },
          });
        }

        // Error occurred during switch Tenant Action, so do nothing. Let the action resolver do the right thing
        // i.e., either redirect to Permission denied page or show Permission Denied Component.
        return generateAction();
      }

      return undefined;
    };

    // CASE #5: Tenant ID is not in the URL, so transparently update it.
    // This allows us to use "plain" urls throughout the app, but still end up with the tenant in the URL.
    const urlShouldIncludeTenantEvaluator = () => {
      if (tenant && !requestedTenantUrn) {
        return generateAction({
          location: {
            ...location,
            pathname: buildTenantScopedUrl(tenant, location.pathname),
          },
        });
      }

      return undefined;
    };

    // CASE #6: This state is a direct result of CASE #4. Do nothing since the tenant in store hasn't changed, just the URL.
    // This case is primarily here as a guard against hitting CASE #7 and causing a redirect-loop
    const blacklistedUrlUpdatedEvaluator = () => {
      if (
        !currentTenantUrn &&
        !isSameTenant(currentTenantUrn, requestedTenantUrn) &&
        tenant &&
        isSameTenant(tenant.id, requestedTenantUrn)
      ) {
        return generateAction();
      }

      return undefined;
    };

    // CASE #7. The tenant should change since the tenant in URL is different from the one in the store.
    // Authorize and set the new tenant
    const switchTenantEvaluator = () => {
      if (
        !nextProps.loadingNextTenantAuthorization &&
        (!tenant || !isSameTenant(tenant.id, requestedTenantUrn))
      ) {
        return generateAction({
          tenantId: parseTenantIdentifier(requestedTenantUrn),
        });
      }

      return undefined;
    };

    const evaluators = [
      defaultPageEvaluator,
      replaceUrnBasedUrl,
      appLoadingEvaluator,
      hideTenantSelectorEvaluator,
      urlShouldIncludeTenantEvaluator,
      blacklistedUrlUpdatedEvaluator,
      switchTenantEvaluator,
    ];

    for (let i = 0; i < evaluators.length; i += 1) {
      const action = evaluators[i]();
      if (action) {
        action.debug = { id: evaluators[i].name, case: i + 1 };
        return action;
      }
    }

    return generateAction();
  }

  static actionsAreEqual(a, b) {
    // Both are no-ops and therefore equal
    if ((!a || a.noop) && (!b || b.noop)) {
      return true;
    }

    const localA = { ...(a || {}), debug: null };
    const localB = { ...(b || {}), debug: null };

    return isEqual(localA, localB);
  }

  shouldComponentUpdate(nextProps) {
    const { errorNextTenantAuthorization, loadingNextTenantAuthorization } =
      nextProps;
    if (
      errorNextTenantAuthorization !==
        this.props.errorNextTenantAuthorization ||
      loadingNextTenantAuthorization !==
        this.props.loadingNextTenantAuthorization
    ) {
      return true;
    }

    return this.didActionUpdate(nextProps);
  }

  didActionUpdate(nextProps) {
    let action = TenancyActionResolver.resolveAction(this.props, nextProps);
    if (action.noop) {
      action = null;
    }

    if (TenancyActionResolver.actionsAreEqual(this.action, action)) {
      return false;
    }

    this.action = action;
    return true;
  }

  render() {
    return (
      <TenancyActionExecutor
        render={this.props.render}
        action={this.action}
        switchTenant={this.props.switchTenant}
        loadingNextTenantAuthorization={
          this.props.loadingNextTenantAuthorization
        }
        errorNextTenantAuthorization={this.props.errorNextTenantAuthorization}
      />
    );
  }
}

TenancyActionResolver.propTypes = {
  render: PropTypes.func.isRequired,
  tenant: PropTypes.shape({ id: PropTypes.string.isRequired }), // eslint-disable-line react/no-unused-prop-types
  match: PropTypes.shape({
    // eslint-disable-line react/no-unused-prop-types
    url: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    params: PropTypes.shape({
      tenantId: PropTypes.string,
    }).isRequired,
  }),
  location: PropTypes.shape({
    // eslint-disable-line react/no-unused-prop-types
    pathname: PropTypes.string,
  }).isRequired,
  loadedSession: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  tenantSelectorVisible: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
  loadingNextTenantAuthorization: PropTypes.bool.isRequired,
  errorNextTenantAuthorization: PropTypes.bool,
  switchTenant: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  ...sessionSelector(state),
  ...nextTenantAuthorizationSelector(state),
  tenant: activeTenantSelector(state),
  tenantSelectorVisible: tenantSelectorVisibilitySelector(state).enabled,
});

const compareLocations = (a, b) => shallowEqual(omit(a, 'key'), omit(b, 'key'));
const compareMatch = (a, b) => isEqual(a.params, b.params);

/**
 * The primary purpose of this is to prevent an infinite loop of renders by reducing renders overall.
 * Previously, I used `options.pure = false` so it would always render to react to changes.
 * Since ReactRouter props change due to any url update in the app, that resulted in a many renders.
 * @param nextProps
 * @param props
 * @returns {Boolean}
 */
const areOwnPropsEqual = (nextProps, props) => {
  if (shallowEqual(props, nextProps)) {
    return true;
  }

  if (
    shallowEqual(
      omit(props, 'location', 'match'),
      omit(nextProps, 'location', 'match')
    )
  ) {
    return (
      compareLocations(nextProps.location, props.location) &&
      compareMatch(nextProps.match, props.match)
    );
  }

  return false;
};

export default connect(mapStateToProps, { switchTenant }, null, {
  areOwnPropsEqual,
})(TenancyActionResolver);
