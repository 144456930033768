import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { addMenu, removeMenu } from '@infosight/shell-api/lib/PrimaryNav';
import { AuthorizationEvaluator } from '../../../user';
import { experimentalSelector } from '../../../bootstrapper/reducer';
import { isInternalUserSelector } from '../../../user/reducer';

const OculusPrimaryNavExtension = () => {
  const experimental = useSelector(experimentalSelector);
  const isInternalUser = useSelector(isInternalUserSelector);

  useEffect(() => {
    const getResources = () => {
      const options = AuthorizationEvaluator.filter([
        {
          id: 'sfr', // Don't change the id properties without updating the nav sorting
          title: 'HPE Alletra 9000, Primera, 3PAR, StoreOnce',
          depth: 1,
          options: AuthorizationEvaluator.filter([
            {
              id: 'preferences',
              title: 'Preferences',
              url: '/infrastructure/general/preferences',
              access: experimental || { custom: () => isInternalUser },
              nodeType: 'internal',
            },
            {
              id: 'strongpass',
              title: 'Request Secure Password',
              url: '/infrastructure/general/strongpass',
              access: {
                custom: () => isInternalUser,
              },
            },
            {
              id: 'recommendations',
              title: 'Software Recommendations',
              url: '/infrastructure/general/recommendations',
              access: {
                custom: () => isInternalUser,
              },
            },
            {
              id: 'wellness',
              title: 'Wellness Status Checks',
              url: '/infrastructure/general/wellness',
              access: {
                custom: () => isInternalUser,
              },
            },
            {
              id: 'storeservSupportCase',
              title: 'Support',
              url: 'https://support.hpe.com/connect/s/viewcases',
              nodeType: 'external',
            },
            {
              id: 'storeservUserGuide',
              title: 'User Guide',
              url: 'https://support.hpe.com/hpesc/public/docDisplay?docId=a00094611en_us',
              nodeType: 'external',
            },
            {
              id: 'storeservDoc',
              title: 'Documentation',
              url: 'https://support.hpe.com/connect/s/?language=en_US',
              nodeType: 'external',
            },
            {
              id: 'storeservSoftwareDwld',
              title: 'Software Downloads',
              url: 'http://www.hpe.com/downloads/software ',
              nodeType: 'external',
            },
            {
              id: 'storeservCompatibilityMatrix',
              title: 'Compatibility Matrix',
              url: 'https://h20272.www2.hpe.com/SPOCK/index.aspx',
              nodeType: 'external',
            },
            {
              id: 'storeservWelcomeCenter',
              title: 'Welcome Center',
              url: 'https://infosight.hpe.com/welcomecenter/',
              nodeType: 'external',
            },
          ]),
          collapseHeader: false,
        },
      ]);

      return options.length ? options : null;
    };

    const extension = {
      menuId: 'resources',
      id: 'sfr',
      content: getResources(),
    };
    if (extension.content) {
      addMenu(extension);
    } else {
      removeMenu(extension);
    }
  }, [experimental, isInternalUser]);

  return null;
};

export default OculusPrimaryNavExtension;
