import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { Box, Text, Accordion } from 'grommet/es6';
import { Apps, FormClose } from 'grommet-icons';
import { useSelector, useDispatch } from 'react-redux';
import LoadMicroapp from '../../extensibility/orchestrator/LoadMicroapp';
import NavMenuBox from './NavMenuBox';
import NavMenuLink, { isActive } from './NavMenuLink';
import NavAccordionPanel from './NavAccordionPanel';
import style from './NavMenu.scss';
import NavMenuTenant from './NavMenuTenant';
import { updateActivePanels } from '../actionCreators';
import { activePanelSelector, getActivePanels } from '../reducer';

const cx = classnames.bind(style);

// This method returns the index of a menu element if that element is a) a sub-menu, and b) that
// sub-menu contains an element with a url that is currently active within the browser window.
// Note, this will only work with one level sub-menus (which is all we have at the moment).  If
// we have further nesting, this will need to be addressed further.
const getActiveSubMenuIndex = (options) => {
  let activeIndex;
  options.forEach((option, index) => {
    const subOptions = option.options;
    if (subOptions) {
      subOptions.forEach((subOption) => {
        if (subOption.url && isActive(subOption.url)) {
          activeIndex = index + 1;
        }
      });
    }
  });
  return activeIndex;
};

const InAppNav = ({ appId, title, elements, childAppIds, back, close }) => {
  const activePanelStore = useSelector(activePanelSelector);
  const activePanels = getActivePanels(activePanelStore, title);
  const dispatch = useDispatch();

  // Check for accordion panels that should be open because of active current url
  const activeSubMenuIndex = getActiveSubMenuIndex(elements);
  if (activeSubMenuIndex && activePanels.indexOf(activeSubMenuIndex) === -1) {
    // Add it to the current active panels so that the submenu will be opened
    activePanels.push(activeSubMenuIndex);
  }

  return (
    <>
      {(childAppIds || [appId]).map((id) => (
        <LoadMicroapp appId={id} key={id} />
      ))}
      <Accordion
        className={cx('container')}
        onActive={(activeIndexes) => {
          dispatch(updateActivePanels(title, activeIndexes));
        }}
        activeIndex={activePanels}
      >
        <NavMenuBox
          className={classnames(cx('title-bold-green'))}
          data-tour-step="nav-application"
        >
          <div className={cx('flex-container align-middle')}>
            <Box
              margin={{ right: 'small' }}
              className={classnames(
                cx('icon-background'),
                cx('title-highlight')
              )}
              onClick={back}
              data-tour-step="nav-main-menu"
            >
              <div className={cx('icon')} title="Main Menu">
                <Apps />
              </div>
            </Box>
            <Box direction="column">
              <Text>{title}</Text>
              <NavMenuTenant />
            </Box>
            <Box onClick={close}>
              <FormClose visibility="hidden" />
            </Box>
          </div>
        </NavMenuBox>
        {elements.map((data, index) => {
          if (data.url || data.onClickRoute) {
            return <NavMenuLink key={data.id} onNavigate={close} {...data} />;
          }
          const active = activePanels.includes(index + 1);
          return (
            <NavAccordionPanel
              key={data.title}
              title={data.title}
              options={data.options}
              active={active}
              parentPanelKey={title}
              onNavigate={close}
            />
          );
        })}
      </Accordion>
    </>
  );
};

InAppNav.propTypes = {
  appId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  elements: PropTypes.arrayOf(
    PropTypes.oneOfType(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        url: PropTypes.string,
      }),
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        options: PropTypes.array.isRequired,
      })
    )
  ).isRequired,
  childAppIds: PropTypes.arrayOf(PropTypes.string),
  back: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

InAppNav.defaultProps = {
  childAppIds: null,
};

export default InAppNav;
