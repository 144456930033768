import {
  createReducersForAction,
  createSelector,
  getInitialState,
} from '@infosight/elmer/dist/utils/redux';
import { SEARCH_UNIFIED_ASSETS } from './constants';

const searchResultsStateKey = 'searchResults';

const initialState = {
  ...getInitialState('unifiedAssets'),
};

const ACTION_HANDLERS = {
  ...createReducersForAction({
    type: SEARCH_UNIFIED_ASSETS,
    stateKey: searchResultsStateKey,
  }),
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

const baseSelector = (state) => state.unifiedAssets;

export const searchSelector = createSelector(
  baseSelector,
  searchResultsStateKey
);
