import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { addMenu, removeMenu } from '@infosight/shell-api/lib/PrimaryNav';
import {
  AuthorizationEvaluator,
  AUTHORITIES,
  nimbleTenantAdminAccess,
} from '../../../user';
import { experimentalSelector } from '../../../bootstrapper/reducer';
import {
  nimbleTenantSelector,
  userWithIAMSelector,
} from '../../../user/reducer';

const NimblePrimaryNavExtension = () => {
  const nimbleTenant = useSelector(nimbleTenantSelector);
  const experimental = useSelector(experimentalSelector);
  // need to rerun when the authorities are actually loaded
  const user = useSelector(userWithIAMSelector);
  const auth = (user && user.authorities) || null;

  useEffect(() => {
    const getResources = () => {
      const options = AuthorizationEvaluator.filter([
        {
          id: 'nimble', // Don't change the id properties without updating the nav sorting
          title: 'Nimble Storage',
          depth: 1,
          options: AuthorizationEvaluator.filter([
            {
              id: 'support',
              title: 'Support',
              url: '/resources/nimble/support',
              access: {
                custom: () =>
                  nimbleTenant &&
                  AuthorizationEvaluator.evaluate({
                    access: {
                      $or: [
                        {
                          authorities: AUTHORITIES.PRODUCT_FAMILY_NIMBLE,
                        },
                        {
                          authorities: [AUTHORITIES.SUPPORT_CASE],
                        },
                      ],
                    },
                  }),
              },
            },
            {
              id: 'docs',
              title: 'Documentation',
              url: '/resources/nimble/docs',
              access: {
                authorities: [
                  AUTHORITIES.PRODUCT_FAMILY_NIMBLE,
                  AUTHORITIES.PRODUCT_FAMILY_STOREONCE,
                  AUTHORITIES.PRODUCT_FAMILY_STORESERV,
                ],
              },
            },
            {
              id: 'software',
              title: 'Software Downloads',
              url: '/resources/nimble/software',
              access: {
                custom: () =>
                  nimbleTenant &&
                  AuthorizationEvaluator.evaluate({
                    access: {
                      authorities: AUTHORITIES.PRODUCT_FAMILY_NIMBLE,
                    },
                  }),
              },
            },
            {
              id: 'vmarray',
              title: 'Virtual Array Licenses',
              url: '/resources/nimble/vmarray',
              access: {
                custom: () =>
                  nimbleTenant &&
                  AuthorizationEvaluator.evaluate({
                    access: {
                      authorities: [AUTHORITIES.VIRTUAL_ARRAY_ACCESS],
                    },
                  }),
              },
            },
            {
              id: 'validated-configuration-matrix',
              title: 'Validated Configuration Matrix',
              url: '/resources/nimble/validated-configuration-matrix',
              access: {
                authorities: [
                  AUTHORITIES.PRODUCT_FAMILY_NIMBLE,
                  AUTHORITIES.REALM_INTERNAL,
                ],
              },
            },
            {
              id: 'connect',
              title: 'Nimble Connect',
              url: 'https://community.hpe.com/t5/HPE-Nimble-Storage/ct-p/nimble-storage',
              nodeType: 'external',
            },
            {
              id: 'audit-report',
              title: 'Audit Report',
              url: '/resources/nimble/audit-report',
              access: {
                custom: () =>
                  nimbleTenant &&
                  AuthorizationEvaluator.evaluate({
                    access: nimbleTenantAdminAccess,
                  }),
              },
              nodeType: 'internal',
              divider: true,
            },
            {
              id: 'welcome-center',
              title: 'Welcome Center',
              url: `${window.location.origin}/welcomecenter/`,
              nodeType: 'external',
            },
          ]),
          collapseHeader: false,
        },
      ]);

      return options.length ? options : null;
    };

    [
      {
        menuId: 'resources',
        id: 'nimble',
        content: getResources({ experimental }),
      },
    ].forEach((extension) => {
      if (extension.content) {
        addMenu(extension);
      } else {
        removeMenu(extension);
      }
    });
  }, [nimbleTenant, experimental, auth]);

  return null;
};

export default NimblePrimaryNavExtension;
