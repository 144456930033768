import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { connect } from 'react-redux';
import { hitsGroupedByTopicSelector, noHitsSelector } from '../reducer';
import TopicHits from './TopicHits';
import style from './style.scss';

const cx = classnames.bind(style);

class Hits extends PureComponent {
  render() {
    const { hits, expandedTopic, toggleExpansion, closeMenu, noHitsFound } =
      this.props;
    return (
      <div
        className={cx('results')}
        ref={(el) => {
          if (el) {
            const { top } = el.getBoundingClientRect();
            // eslint-disable-next-line no-param-reassign
            el.style.cssText = `max-height: calc(100vh - ${top}px);`;
          }
        }}
      >
        {hits &&
          hits.map((topic) => {
            const isExpanded = expandedTopic === topic.topic.id;
            const visibleCount = isExpanded ? topic.hits.length : 5;

            return (
              <TopicHits
                {...topic}
                key={topic.topic.id}
                visibleCount={visibleCount}
                toggleExpansion={toggleExpansion}
                closeMenu={closeMenu}
              />
            );
          })}
        {noHitsFound && <div className={cx('no-match')}>No matches found.</div>}
      </div>
    );
  }
}

Hits.propTypes = {
  expandedTopic: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  toggleExpansion: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
  hits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  noHitsFound: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  hits: hitsGroupedByTopicSelector(state),
  noHitsFound: noHitsSelector(state),
});

export default connect(mapStateToProps)(Hits);
