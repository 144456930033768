import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'react-autobind';
import classnames from 'classnames/bind';
import { Spinner } from '@infosight/elmer/dist/components/Spinner';
import { IconAdaptor } from '@infosight/elmer/dist/components/IconAdaptor';
import { FaSearch } from 'react-icons/fa';
import style from './SearchQueryMenuItem.scss';

const cx = classnames.bind(style);

class SearchQueryMenuItem extends Component {
  constructor(props) {
    super(props);
    autobind(this);
    this.ref = React.createRef();
  }

  componentDidMount() {
    const el = this.ref.current;
    if (el) {
      el.focus();
    }
  }

  render() {
    const { loadingTenantSearchHits, onChange, onKeyPress, searchRef } =
      this.props;
    return (
      <div
        className={classnames(
          'flex-container flex-dir-row align-middle',
          cx('search')
        )}
        ref={searchRef}
      >
        <div className="flex-child-grow" style={{ position: 'relative' }}>
          <input
            placeholder="Search"
            type="text"
            onChange={onChange}
            onKeyPress={onKeyPress}
            id="tenant-search"
            ref={this.ref}
            autoComplete="off"
          />
        </div>
        {loadingTenantSearchHits && <Spinner size="small" color="white" />}
        <label htmlFor="tenant-search">
          <IconAdaptor size="nav" type="svg">
            <FaSearch />
          </IconAdaptor>
        </label>
      </div>
    );
  }
}

SearchQueryMenuItem.propTypes = {
  loadingTenantSearchHits: PropTypes.bool.isRequired,

  /**
   * @callback
   * @param {string} query
   */
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  searchRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLInputElement) }),
  ]),
};

SearchQueryMenuItem.defaultProps = {
  searchRef: undefined,
};

export default SearchQueryMenuItem;
