import React from 'react';
import { Link } from 'react-router-dom';
import { UserAdmin } from 'grommet-icons';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import {
  ClientControlledTable,
  CheckBoxFilter,
} from '@infosight/elmer/dist/components/Table';
import { Box, Text } from 'grommet';
import {
  IAM_ROLES_LIST,
  IAM_TO_UI_ROLE_MAP,
  UI_TO_IAM_ROLE_MAP,
} from '../constants';
import style from './MyOrgsList.scss';

const cx = classnames.bind(style);

export const filterRoleMethod = (filter = {}, row) => {
  const { id, value } = filter;
  const rowValue = String(row[id]).toLowerCase();

  if (Array.isArray(value)) {
    const mapped = value.map((it) => UI_TO_IAM_ROLE_MAP[it]);
    return mapped.some((v) => rowValue === String(v).toLowerCase());
  }

  const mapped = UI_TO_IAM_ROLE_MAP[value];
  return row[id] !== undefined
    ? rowValue.indexOf(String(mapped).toLowerCase()) !== -1
    : true;
};

const MyOrgsList = ({ data, loading }) => {
  const getTableConfig = () => {
    return [
      {
        id: 'name',
        Header: 'Organization',
        accessor: 'tenant.name',
        filterable: false,
        /* eslint-disable react/prop-types */
        Cell: ({
          original: {
            tenant: { name, id, description },
          },
        }) => (
          <>
            <Link
              className={cx('org-name')}
              to={`/org/urn%3Ainfosight%3A${id}`}
            >
              {name}
            </Link>
            {description && description.length > 0 && (
              <div className={cx('org-desc')}>{description}</div>
            )}
          </>
        ),
        /* eslint-enable react/prop-types */
      },
      {
        id: 'role',
        Header: 'Your Role',
        accessor: 'role',
        sortable: true,
        filterable: true,
        filterMethod: filterRoleMethod,
        Filter: () => (
          <CheckBoxFilter
            checkboxItems={IAM_ROLES_LIST.map((role) => ({
              label: role,
              value: role,
            }))}
          />
        ),
        Cell: ({ original: { role } }) => {
          const roleElement = (
            <Box fill direction="row" align="center">
              {role === 'ADMIN' && (
                <Box margin={{ right: 'xsmall' }}>
                  <UserAdmin size="16px" color="currentColor" />
                </Box>
              )}
              <Text size="small">{IAM_TO_UI_ROLE_MAP[role]}</Text>
            </Box>
          );
          return role ? roleElement : null;
        },
      },
    ];
  };

  return (
    <ClientControlledTable
      className={cx('my_orgs_table')}
      tableId="MY_ORG_LIST"
      columns={getTableConfig()}
      data={data}
      loading={loading}
      defaultSorted={[{ id: 'name', desc: false }]}
      syncUrl={false}
    />
  );
};

MyOrgsList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      tenant: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
      }),
      role: PropTypes.string.isRequired,
    })
  ),
  loading: PropTypes.bool,
  original: PropTypes.shape({
    role: PropTypes.string.isRequired,
    // Include other properties of original as needed
  }).isRequired,
};

export default MyOrgsList;
