import URI from 'urijs';
import axios from './axios';
import { API_BASE_URL } from '../config';

/**
 * Fluent interface to make requests to Pod and Central APIs
 * HTTP-based methods proxy to [axios](https://github.com/mzabriskie/axios#request-method-aliases) to execute the
 * request. The others are backed by [URIjs](https://medialize.github.io/URI.js) to build URI, which is used by the
 * HTTP methods.
 *
 */
export default class Request {
  constructor() {
    this.uri = new URI(API_BASE_URL);
  }

  uriAddQuery(q) {
    this.uri.addQuery(q);
    return this;
  }

  /**
   * Get the URIjs instance. Note that this will break the method chain.
   * @return {URI}
   */
  uriBuilder() {
    return this.uri;
  }

  // give this any number of string args and it will append each one to the current uri
  slash(...args) {
    for (let i = 0; i < args.length; i += 1) {
      // segment only appends if you use exactly one string argument
      // so to prevent something unexpected happening, we only accept string args
      if (typeof args[i] !== 'string') {
        throw new Error('slash() only accepts string arguments');
      }
      this.uri.segment(args[i]);
    }
    return this;
  }

  query(queries) {
    this.uri.addQuery(queries);
    return this;
  }

  // Nimble request builders

  /**
   * Construct a URL for central resource that is managed in central app
   */
  central() {
    this.uri.segment('central');
    return this;
  }

  /**
   * @param view
   * @param {Array<String>} subviews
   * @return {Request}
   */
  genericQuery(view, subviews) {
    this.uri.segment('genericQuery').segment(view);

    if (subviews) {
      subviews.reduce(
        (finalUrl, subView) => finalUrl.segment(subView),
        this.uri
      );
    }

    return this;
  }

  /** Proxy through APIGEE
   * @return {Request}
   */
  apigee() {
    this.uri = new URI('/apis/');
    return this;
  }

  // SFR request builders

  /**
   * This resets the URI to a SFR api request from a Nimble request.
   * Eventually this will be flipflopped when the far superior
   * SFR API becomes the dominant API of InfoSight ;)
   */
  sfr(endpoint) {
    if (endpoint === 'auth') {
      this.uri = new URI('/auth/');
    } else {
      this.uri = new URI('/api/');
      this.uri.segment(endpoint);
    }
    return this;
  }

  /**
   * This resets the URI to a SFR auth request from a Nimble request.
   */
  auth() {
    this.uri = new URI('/auth/');
    return this;
  }

  // request calls

  get(...args) {
    return axios.get(this.uri.valueOf(), ...args);
  }

  put(...args) {
    return axios.put(this.uri.valueOf(), ...args);
  }

  post(...args) {
    return axios.post(this.uri.valueOf(), ...args);
  }
}
